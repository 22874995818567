import { createSlice } from '@reduxjs/toolkit';
// utils
import ym from 'react-yandex-metrika';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  errorForm: false,
  successForm: false,
  sources: '',
  sourcesUrl: '',
  description: '',
  rating: 0,
  lang: '',
  tags: []
};

const slice = createSlice({
  name: 'demoDes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setMainInfo(state, action) {
      const { description, tags } = action.payload.main;
      const { imageUrl } = action.payload;
      const url = action.payload.main.image_uuid;

      return {
        ...state,
        sources: imageUrl,
        // sourcesUrl: action.payload.main.image_uuid,
        sourcesUrl: url,
        description,
        tags
      };
    },
    hasError(state, action) {
      state.error = true;

      switch (action.payload.code) {
        case 'file-invalid-type':
          state.errorMessage =
            window.navigator.language === 'ru'
              ? 'Извините, загруженный файл не поддерживается. Пожалуйста, загрузите файл в формате JPEG или PNG'
              : 'Sorry, the uploaded file is not supported. Please upload a JPEG or PNG file';
          break;
        case 'file-too-large':
          state.errorMessage =
            window.navigator.language === 'ru'
              ? 'Извините, размер файла должен быть меньше 15 МБ'
              : 'Sorry, the file size must be less than 15 MB';
          break;
        case 'url-didnt-load':
          state.errorMessage =
            window.navigator.language === 'ru' ? 'Не удалось загрузить картинку' : 'Failed to upload picture';
          break;
        default:
          state.errorMessage = window.navigator.language === 'ru' ? 'Неизвестный тип ошибки' : 'Unknown error type';
          break;
      }
      // state.errorMessage = action.payload
    },
    delError(state) {
      state.error = false;
    },
    alertErrorClose(state) {
      state.errorForm = false;
    },
    alertSuccessClose(state) {
      state.successForm = false;
    },
    hasAlertError(state) {
      state.errorForm = true;
    },
    hasAlertSuccess(state) {
      state.successForm = true;
    },
    changeLang(state, action) {
      state.lang = action.payload;
    },
    clearStore(state) {
      state.sources = '';
      state.description = '';
      state.tags = [];
      state.sourcesUrl = '';
      state.rating = 0;
    },
    changeRatingStore(state, action) {
      return {
        ...state,
        rating: action.payload
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getMorePosts,
  delError,
  startLoading,
  setMainInfo,
  endLoading,
  takeDataFromExample,
  hasError,
  alertErrorClose,
  alertSuccessClose,
  clearStore,
  changeLang
} = slice.actions;

// ----------------------------------------------------------------------

export function getDescriptionFromURL(imageUrl, lang) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.delError());
    console.log('imageUrlBefore', imageUrl);
    ym('reachGoal', 'getDescriptionURL', { awesomeParameter: 88092630 });
    try {
      const response = await axios.post(`/api/demo/description/url`, {
        imageUrl,
        lang
      });
      // const response = await instance.post('/api/demo/description/url', { url });
      const data = { main: response.data, imageUrl };
      dispatch(slice.actions.setMainInfo(data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ code: 'url-didnt-load', message: 'Не удалось загрузить картинку' }));
      // const dataError = { main: { image_uuid: 'error.error' }, imageUrl };
      // dispatch(slice.actions.setMainInfo(dataError));
      dispatch(slice.actions.endLoading());
    }
  };
}

export function getDescriptionFromFileImg(file, lang) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.delError());
    ym('reachGoal', 'getDescriptionFile', { awesomeParameter: 88092630 });
    try {
      const data = new FormData();

      data.append('image_file', file);
      data.append('lang', lang);
      const response = await axios.post('/api/demo/description/file', data);
      const dataSend = { main: response.data, imageUrl: '' };
      dispatch(slice.actions.setMainInfo(dataSend));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError({ code: 'url-didnt-load', message: 'Не удалось загрузить картинку' }));
      dispatch(slice.actions.endLoading());
    }
  };
}

export function sendContactUsFromMessage(name, email, message, id, lang) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/demo/description/contact', {
        image_uuid: id,
        name,
        email,
        feedback: message,
        lang
      });
      console.log(response);
      dispatch(slice.actions.hasAlertSuccess());
    } catch (error) {
      if (error.message === 'Request failed with status code 501') {
        dispatch(slice.actions.hasAlertSuccess());
      } else {
        dispatch(slice.actions.hasAlertError());
      }
      // console.log(error.message, error.name);
    }
  };
}

export function changeRating(rating, id) {
  return async (dispatch) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post('/api/demo/description/rating', { image_uuid: id, rating });
      dispatch(slice.actions.changeRatingStore(rating));
    } catch (error) {
      console.log(error);
    }
  };
}
