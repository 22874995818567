/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from '@iconify/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Container, Typography } from '@material-ui/core';
import Alert from '@material-ui/core/Alert';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack5';
import useLocales from '../../hooks/useLocales';

// components
import Page from '../../components/Page';
import ResetPasswordForm from '../../components/authentication/forgot-password/ResetPasswordForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  const { translate } = useLocales();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, notification, isPasswordChanged } = useSelector((state) => state.user);

  useEffect(() => {
    if (isPasswordChanged) {
      enqueueSnackbar(translate('reset_password.snackbarSuccess'), { variant: 'success' });
      navigate('/');
    }
  }, [isPasswordChanged]);

  return (
    <RootStyle title="Verify | Minimal UI">
      {/* <LogoOnlyLayout /> */}

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Button
            size="small"
            component={RouterLink}
            to="/"
            startIcon={<Icon icon={arrowIosBackFill} width={20} height={20} />}
            sx={{ mb: 3 }}
          >
            {translate('reset_password.backButton')}
          </Button>

          <Typography variant="h3" paragraph>
            {translate('reset_password.enterNewPassword')}
          </Typography>

          {notification.isNotification && (
            <Alert sx={{ mb: 0.5 }} severity={notification.notificationType}>
              {notification.notificationMessage}
            </Alert>
          )}

          <Box sx={{ mt: 5, mb: 3 }}>
            <ResetPasswordForm loading={isLoading} />
          </Box>
        </Box>
      </Container>
    </RootStyle>
  );
}
