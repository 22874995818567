/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, ImageList, ImageListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useDispatch } from '../../redux/store';
import { getDescriptionFromURL } from '../../redux/slices/demoDes';
import styles from './Gallery.module.css';

// Images
import canadaGoose from '../../customImages/canada-goose-6578211_1920.jpg';
import child from '../../customImages/child-613199_1280.jpg';
import hartmans from '../../customImages/hartmanns-4823240_1920.jpg';
import kiteSurfing from '../../customImages/kite-surfing-1960616_1920.jpg';
import motorCycle from '../../customImages/motorcycle-77552_1920.jpg';
import snow from '../../customImages/snow-3110761_1920.jpg';

import { useWindowDimensions } from '../../customHooks/customHooks';

const imagesData = [
  {
    sources: canadaGoose
  },
  {
    sources: hartmans
  },
  {
    sources: kiteSurfing
  },
  {
    sources: motorCycle
  },
  {
    sources: snow
  },
  {
    sources: child
  }
];

function Gallery() {
  const [rowHeight, setRowHeight] = React.useState(0);

  const { lang } = useSelector((state) => state.demoDes);

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  const handleReturnRowHeight = () => {
    if (width <= 768 && width > 425) {
      setRowHeight(142);
    } else if (width <= 425 && width > 320) {
      setRowHeight(80);
    } else if (width <= 320) {
      setRowHeight(80);
    } else setRowHeight(142);
  };

  // Ширина экрана

  React.useEffect(() => {
    handleReturnRowHeight();
  }, [width]);

  const handleShowImageDescription = (sources) => {
    const host = window.location.origin;

    if (sources[0] === '.') {
      sources = sources.substr(1);
    }

    const imgSrc = host + sources;
    dispatch(getDescriptionFromURL(imgSrc, lang));
  };

  return (
    <Container maxWidth="xs" className={styles.gallery} disableGutters>
      <ImageList cols={3} rowHeight={rowHeight} gap={width <= 425 ? 5 : 15}>
        {imagesData.map((item, index) => (
          <ImageListItem key={index} sx={{ width: '100%' }} onClick={() => handleShowImageDescription(item.sources)}>
            <img
              src={`${item.sources}`}
              alt="example"
              loading="lazy"
              className={styles.image}
              style={{ maxHeight: rowHeight }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}

export default Gallery;
