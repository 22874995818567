import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  confirmToken: null,
  successPay: false,
  failPay: false,
  price: null,
  count: null
};

const slice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = true;
      state.errorMessage = action?.payload?.message || '';
    },
    setConfirmToken(state, action) {
      state.confirmToken = action?.payload || null;
    },
    deleteConfirmToken(state) {
      state.confirmToken = null;
    },
    setSuccessPay(state) {
      state.successPay = true;
      state.failPay = false;
    },
    setFailPay(state) {
      state.successPay = false;
      state.failPay = true;
    },
    setPrice(state, action) {
      state.price = action?.payload || null;
    },
    setCount(state, action) {
      state.count = action?.payload || null;
    }
  }
});

export default slice.reducer;

export const {
  startLoading,
  endLoading,
  hasError,
  setConfirmToken,
  deleteConfirmToken,
  initCheckout,
  destroyCheckout,
  setSuccessPay,
  setFailPay,
  setPrice,
  setCount
} = slice.actions;

export function getConformationToken(amount, currency = 'RUB') {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.post('/api/payment/yookassa', {
        amount,
        currency
      });
      const confirmationToken = (await response?.data?.confirmation_token) || null;
      dispatch(slice.actions.setConfirmToken(confirmationToken));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      console.log(error);
    }
  };
}
