/* eslint-disable react/button-has-type */
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
// import { fetchUser, getUserFail, getUserPending, getUserSuccess } from '../../../../redux/slices/userSlice.ts';
// import { loginPending, userGoogleLogin, loginSuccess, loginFail } from '../../../../redux/slices/userGoogleYandexSlice';
import GoogleIcon from '@mui/icons-material/Google';
import { clientIdGoogle } from '../../../configs';
import { loginWithGoogleAction } from '../../../redux/slices/user';

const useStyles = makeStyles(() => ({
  customBtn: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #d4d4d4',
    transition: 'all 0.3s ease',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d4d4d4'
    }
  }
}));

export default function LoginGoogle() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onLoginSuccess = async (res) => {
    // получение токена
    const idToken = res?.tokenId || null;
    if (idToken) {
      // dispatch(loginPending());
      // передача на бэк
      try {
        dispatch(loginWithGoogleAction(idToken));
      } catch (error) {
        console.log(error);
        //   dispatch(loginFail({ err: error.message }));
      }
    }
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientIdGoogle}
        render={(renderProps) => (
          <button onClick={renderProps.onClick} className={classes.customBtn}>
            <GoogleIcon />
          </button>
        )}
        onSuccess={onLoginSuccess}
        cookiePolicy="single_host_origin"
      />
    </div>
  );
}
