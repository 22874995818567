/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { useDispatch } from 'react-redux';
import useLocales from '../../hooks/useLocales';
import { closeModalAuth, loginAction } from '../../redux/slices/user';

export default function LoginForm({ loading }) {
  const { translate, currentLang } = useLocales();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('validationsMessages.emailValid'))
      .required(translate('validationsMessages.emailRequired')),
    password: Yup.string().required(translate('validationsMessages.passwordRequired'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (e) => {
      dispatch(loginAction(e));
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" disabled={loading || false} noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            disabled={loading}
            label={translate('login_page.email_address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            disabled={loading}
            label={translate('general.password')[0].toUpperCase() + translate('general.password').slice(1)}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={translate('login_page.remember_me')}
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={`/${currentLang.value}/forgot`}
            onClick={() => dispatch(closeModalAuth())}
          >
            {translate('login_page.forgot_password')}
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} disabled={loading}>
          {translate('login_page.login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
