/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import { Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import useLocales from '../../hooks/useLocales';
import { getDescriptionFromURL, hasError } from '../../redux/slices/demoDes';
import { useDispatch } from '../../redux/store';

// стили для формы отправки url

export default function PasteUrlDemoPageMobile(props) {
  const { translate } = useLocales();
  const { callBackSetFile } = props;
  const [value, setValue] = React.useState('');
  const [isUrlValid, setIsUrlValid] = React.useState(false);
  const miniMobile = useMediaQuery('@media (min-width:420px)');
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const { lang } = useSelector((state) => state.demoDes);

  useEffect(() => {
    const url = value;
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    if (reg.test(url)) {
      setIsUrlValid(true);
    } else {
      setIsUrlValid(false);
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const buttonClick = () => {
    if (isUrlValid) {
      dispatch(getDescriptionFromURL(value, lang));
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0].size < 1024 * 1024 * 15) {
      if (
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/heic' ||
        e.target.files[0].type === 'image/heif' ||
        e.target.files[0].type === 'image/webp' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        callBackSetFile(e.target.files[0], lang);
      } else {
        dispatch(hasError({ code: 'file-invalid-type' }));
      }
    } else {
      dispatch(hasError({ code: 'file-too-large' }));
    }
  };
  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      buttonClick();
    }
  };

  return (
    <form noValidate autoComplete="off" sx={{ width: '100%' }}>
      <TextField
        id="outlined-multiline-flexible"
        label={translate('general.input', { lng: lang })}
        maxRows={1}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        variant="outlined"
        sx={{ width: '100%', mb: '30px' }}
      />
      <Box component="div" display="flex" justifyContent="space-between">
        <input ref={fileInput} type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
        <Button variant="contained" size="large" onClick={() => fileInput.current.click()} sx={{ mr: '15px' }}>
          {miniMobile
            ? translate('page_demo.mobile_choice_photo_with_file', { lng: lang })
            : translate('page_demo.mobile_choice_photo_with_file_s', { lng: lang })}
        </Button>
        <Button
          sx={{}}
          variant="contained"
          size="large"
          color={isUrlValid ? 'primary' : 'inherit'}
          onClick={buttonClick}
          disabled={!isUrlValid}
        >
          {translate('general.click', { lng: lang })}
        </Button>
      </Box>
    </form>
  );
}
