import tariffPlans from './mock-data/tariffPlans';

const getPriceOfTariffPlan = (...args) => {
  const [nameOfPlan] = args;

  if (!nameOfPlan) return null;

  const selectedTariffPlan = tariffPlans.find((plan) => plan?.id === nameOfPlan);
  if (!selectedTariffPlan) return null;
  if (!Object.hasOwn(selectedTariffPlan, 'price')) return null;

  if (Object.hasOwn(selectedTariffPlan, 'discount')) return selectedTariffPlan.price / 2;

  return selectedTariffPlan.price;
};

export default getPriceOfTariffPlan;
