import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

// eslint-disable-next-line react/prop-types
function ModalUniversal({ title, errorText, isOpen, onCloseModal, children }) {
  return (
    <>
      {!children ? (
        <Dialog open={isOpen} maxWidth="lg" onClose={onCloseModal}>
          <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onCloseModal} variant="outlined" color="success">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default ModalUniversal;
