/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Slider from 'react-slick';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch } from '../../redux/store';
import { hasError, removeBgFromFileImg, removeBgFromURL } from '../../redux/slices/demoBGR';
import img1 from '../../customImages/girlEat.png';
import img2 from '../../customImages/denush.png';
import img3 from '../../customImages/rebemok.png';
import img4 from '../../customImages/spina.png';
import useLocales from '../../hooks/useLocales';

const imagesData = [
  {
    sources: img1
  },
  {
    sources: img2
  },
  {
    sources: img3
  },
  {
    sources: img4
  },
  {
    sources: img1
  },
  {
    sources: img2
  },
  {
    sources: img3
  },
  {
    sources: img4
  }
];

export default function TryItYourself() {
  const [lang] = React.useState(localStorage.getItem('lang'));
  const { translate } = useLocales();

  const NextArrow = ({ onClick }) => (
    <Box component="div" className="arrow next" onClick={onClick}>
      <ArrowForwardIosRoundedIcon height="14px" />
    </Box>
  );

  const PrevArrow = ({ onClick }) => (
    <Box component="div" className="arrow prev" onClick={onClick}>
      <ArrowBackIosRoundedIcon height="14px" />
    </Box>
  );

  const [imageIndex, setImageIndex] = useState(0);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const handleFileUpload = (e) => {
    if (e.target.files[0].size < 1024 * 1024 * 15) {
      if (
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/heic' ||
        e.target.files[0].type === 'image/heif' ||
        e.target.files[0].type === 'image/webp' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        dispatch(removeBgFromFileImg(e.target.files[0], lang));
      } else {
        dispatch(hasError({ code: 'file-invalid-type' }));
      }
    } else {
      dispatch(hasError({ code: 'file-too-large' }));
    }
  };

  const clickOnImg = (sources) => {
    const host = window.location.origin;
    if (sources[0] === '.') {
      sources = sources.substr(1);
    }
    const imgSrc = host + sources;
    dispatch(removeBgFromURL(imgSrc, lang));
  };

  const settings = {
    infinite: true,
    lazyLoad: true,
    centerPadding: 0,
    slidesToShow: 4,
    speed: 300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Box sx={{ backgroundColor: '#F4F6F8', p: '60px 35px' }}>
      <Typography align="center" variant="h3" sx={{ mb: '12px' }}>
        {translate('bgRemove.try_it_yourself.title')}
      </Typography>
      <Typography align="center" variant="body2" sx={{ mb: '24px', color: '#212B36' }}>
        {translate('bgRemove.try_it_yourself.sub_title')}
      </Typography>
      <Box component="div" sx={{ maxWidth: '760px', m: '0 auto' }}>
        <Slider {...settings}>
          {imagesData.map((e, idx) => (
            <Box
              key={idx}
              component="div"
              className={idx === imageIndex ? 'slide activeSlide' : 'slide'}
              onClick={() => clickOnImg(e.sources)}
            >
              <Box component="img" src={`${e.sources}`} />
            </Box>
          ))}
        </Slider>
      </Box>
      <Box component="div" sx={{ width: '100%', textAlign: 'center', mt: '46px' }}>
        <input ref={fileInput} type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
        <Button onClick={() => fileInput.current.click()} variant="contained">
          {translate('bgRemove.try_it_yourself.file_choice')}
        </Button>
      </Box>
    </Box>
  );
}
