import axios from 'axios';

// ----------------------------------------------------------------------

// default config for dev env
let axiosConfig = {
  timeout: 30000 // ms
};

if (process.env.NODE_ENV === 'production') {
  axiosConfig = {
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: 15000 // ms
  };
}

const instance = axios.create(axiosConfig);

instance.interceptors.request.use(
  (config) => {
    try {
      const access = localStorage.getItem('access_token');
      if (access) {
        config.headers.Authorization = `Bearer ${access}`;
      }
      return config;
    } catch (error) {
      return config;
    }
  },
  (error) => {
    console.log(error);
    // return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refresh = localStorage.getItem('refresh_token');
          const rs = await axios.post(
            '/api/auth/renew',
            {},
            {
              headers: {
                Authorization: `Bearer ${refresh}`
              }
            }
          );
          localStorage.setItem('access_token', rs.data.access_token);
          localStorage.setItem('refresh_token', refresh);
          instance.defaults.headers.Authorization = `Bearer ${rs.data.access_token}`;
          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
