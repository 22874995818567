import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage';
// slices
import demoBGRReducer from './slices/demoBGR';
import demoDesReducer from './slices/demoDes';
import payReducer from './slices/pay';
import userReducer from './slices/user';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  demoBGR: demoBGRReducer,
  demoDes: demoDesReducer,
  pay: payReducer,
  user: userReducer
});

export { rootPersistConfig, rootReducer };
