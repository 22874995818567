/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/core/Rating';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from '../../redux/store';
import { changeRatingRB } from '../../redux/slices/demoBGR';
import { mainProxy } from '../../configs';
import useLocales from '../../hooks/useLocales';
import { openModalAuth, setNotification } from '../../redux/slices/user';

export default function CardsElem(props) {
  const mobile = useMediaQuery('@media (min-width: 900px)');
  const mini = useMediaQuery('@media (min-width: 550px)');
  const { translate } = useLocales();
  const [ratingCheck, setRatingCheck] = useState(false);
  const [original, setOriginal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);

  const downloadClick = async () => {
    setIsLoading(true);
    const refresh = localStorage.getItem('access_token');
    try {
      const response = await axios.get(`/api/demo/removebg/resulthd?image_uuid=${props.data.image_uuid}`, {
        headers: {
          Authorization: `Bearer ${refresh}`
        },
        responseType: 'blob'
      });

      fileDownload(response.data, 'imageHD.png');
    } catch (e) {
      console.log('err');
    }
    setIsLoading(false);
  };

  const changeRatingFun = (val) => {
    dispatch(changeRatingRB(val, props.number, props.data.image_uuid));
    setRatingCheck(true);
    setTimeout(() => setRatingCheck(false), 4000);
  };

  const clickHDButtonHandler = () => {
    dispatch(openModalAuth());
    dispatch(setNotification());
  };

  return (
    <Box
      component="div"
      sx={
        mini
          ? {
              position: 'relative',
              p: '45px',
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
              borderRadius: '20px',
              mb: '45px'
            }
          : {
              position: 'relative',
              p: '20px',
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
              borderRadius: '20px',
              mb: '45px'
            }
      }
      name={props.data.image_uuid}
    >
      <Box component="div" sx={{ mb: '12px' }}>
        <Button
          variant="primary"
          onClick={() => setOriginal(true)}
          disabled={original}
          sx={{ color: original ? '#065176!important' : '#c3c3c3' }}
        >
          {translate('bgRemove.image_array_cards.card_elem.original')}
        </Button>
        <Button
          variant="primary"
          onClick={() => setOriginal(false)}
          disabled={!original}
          sx={{ color: !original ? '#065176!important' : '#c3c3c3' }}
        >
          {translate('bgRemove.image_array_cards.card_elem.background_removed')}
        </Button>
      </Box>
      <Grid container spacing={8} sx={{ mb: '12px' }}>
        <Grid item xs={12} md={8}>
          <Box
            component="img"
            src={original ? props.data.sourceImg : `${mainProxy}/${props.data.sourceImgBG}`}
            sx={{
              width: '100%',
              borderRadius: '20px',
              backgroundColor: '#fff',
              backgroundSize: '25px',
              backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3LjkzNyA3LjkzOCIgaGVpZ2h0PSIzMCIgd2lkdGg9IjMwIj48cGF0aCBwYWludC1vcmRlcj0ic3Ryb2tlIGZpbGwgbWFya2VycyIgZD0iTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6IiBmaWxsPSIjZWVlZmYwIi8+PC9zdmc+")'
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box
            component="div"
            sx={(() => {
              if (mobile) return { display: 'flex', justifyContent: 'center', flexDirection: 'column' };
              if (mini) return { display: 'flex', justifyContent: 'space-around', flexDirection: 'row', width: '100%' };
              return { display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%' };
            })()}
          >
            {props.data.download.map((elem, i) => {
              if (elem.name !== 'hd') {
                return (
                  <Box key={i} component="div" sx={{ mb: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Button
                      download
                      disabled={isLoading}
                      href={`${mainProxy}/${props.data.sourceImgBG}?dl=1`}
                      variant="contained"
                      size="large"
                      color="grey"
                      sx={{ width: '220px', backgroundColor: '#DFE3E8', m: '0 auto' }}
                    >
                      {translate('bgRemove.image_array_cards.card_elem.download_thumbnail')}
                    </Button>
                    <Typography align="center" variant="body2" sx={{ mt: '12px', color: '#637381', fontSize: '12px' }}>
                      {translate('bgRemove.image_array_cards.card_elem.full_picture')} {elem.resolution}
                    </Typography>
                  </Box>
                );
              }
              return (
                <Box
                  key={i}
                  component="div"
                  sx={!mobile && mini ? { ml: '16px' } : { ml: '0' }}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  {isAuth ? (
                    <Button
                      download
                      disabled={isLoading}
                      onClick={downloadClick}
                      // href={`${mainProxy}/api/demo/removebg/resulthd?image_uuid=${props.data.image_uuid}`}
                      variant="contained"
                      size="large"
                      sx={{ width: '220px', m: '0 auto' }}
                    >
                      {translate('bgRemove.image_array_cards.card_elem.downloadHD')}
                    </Button>
                  ) : (
                    <Button
                      download
                      disabled={isLoading}
                      onClick={clickHDButtonHandler}
                      variant="contained"
                      size="large"
                      sx={{ width: '220px', m: '0 auto' }}
                    >
                      {translate('bgRemove.image_array_cards.card_elem.downloadHD')}
                    </Button>
                  )}
                  <Typography align="center" variant="body2" sx={{ mt: '12px', color: '#637381', fontSize: '12px' }}>
                    {translate('bgRemove.image_array_cards.card_elem.full_picture')} {elem.resolution}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {isLoading && (
            <Box component="div" sx={{ mt: '40px' }}>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress />
              </Box>
              <Typography align="center" variant="body2" sx={{ mt: '24px', color: '#212B36', fontSize: '12px' }}>
                {translate('bgRemove.image_array_cards.card_elem.is_loading_text')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        {/* @todo */}
        {/* <Grid item xs={12} md={8} sx={!mobile && { display: 'none' }}> */}
        <Grid item xs={12} md={8}>
          <Typography variant="body2">
            {' '}
            {translate('bgRemove.image_array_cards.card_elem.preview_text')} {props?.data?.extension || ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" sx={mini ? { mb: '12px', fontSize: '16px' } : { mb: '8px', fontSize: '14px' }}>
            {ratingCheck
              ? translate('bgRemove.image_array_cards.card_elem.thanks')
              : translate('bgRemove.image_array_cards.card_elem.grade')}
          </Typography>
          {ratingCheck ? (
            <CheckIcon color="primary" sx={{ width: '20px', height: '20px' }} />
          ) : (
            <Rating
              size="small"
              name="simple-controlled"
              value={props.data.rating}
              onChange={(e, newValue) => changeRatingFun(newValue)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
