import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import { CircularProgress, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { animateScroll as scroll } from 'react-scroll';
import Button from '@material-ui/core/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ReportIcon from '@mui/icons-material/Report';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from '../../redux/store';
import CardElem from './CardsElem';
import { clearStore, delError, hasError, openModalUrl, removeBgFromFileImg } from '../../redux/slices/demoBGR';
import useLocales from '../../hooks/useLocales';

export default function ImageCards() {
  const [lang] = React.useState(localStorage.getItem('lang'));
  const mobile = useMediaQuery('@media (min-width: 650px)');
  const middle = useMediaQuery('@media (min-width: 900px)');
  const isFirefox = /firefox/.test(navigator?.userAgent?.toLowerCase() || null);
  const { isLoading, image, error, errorMessage } = useSelector((state) => state.demoBGR);
  const { translate } = useLocales();
  const [dropCheck, setDropCheck] = useState(false);
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  useEffect(() => {
    scroll.scrollTo(400, { smooth: 'easeInOutQuint', duration: 700 });
  }, [isLoading]);

  const handleFileUpload = (e) => {
    if (e.target.files[0].size < 1024 * 1024 * 15) {
      if (
        e.target.files[0].type === 'image/jpg' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/heic' ||
        e.target.files[0].type === 'image/heif' ||
        e.target.files[0].type === 'image/webp' ||
        e.target.files[0].type === 'image/jpeg'
      ) {
        dispatch(removeBgFromFileImg(e.target.files[0], lang));
      } else {
        dispatch(hasError({ code: 'file-invalid-type' }));
      }
    } else {
      dispatch(hasError({ code: 'file-too-large' }));
    }
  };

  const dropFunction = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0].size < 1024 * 1024 * 15) {
      if (
        e.dataTransfer.files[0].type === 'image/jpg' ||
        e.dataTransfer.files[0].type === 'image/png' ||
        e.dataTransfer.files[0].type === 'image/heic' ||
        e.dataTransfer.files[0].type === 'image/heif' ||
        e.dataTransfer.files[0].type === 'image/webp' ||
        e.dataTransfer.files[0].type === 'image/jpeg'
      ) {
        dispatch(removeBgFromFileImg(e.dataTransfer.files[0], lang));
      } else {
        dispatch(hasError({ code: 'file-invalid-type' }));
      }
    } else {
      dispatch(hasError({ code: 'file-too-large' }));
    }
    setDropCheck(false);
  };

  const DragStartFun = (e) => {
    e.preventDefault();
    setDropCheck(true);
  };
  const DragLeaveFun = (e) => {
    e.preventDefault();
    setDropCheck(false);
  };

  const urlHandler = () => {
    dispatch(openModalUrl());
  };

  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '100px',
        pl: '15px',
        pr: '15px',
        margin: '0 auto',
        width: '100%',
        maxWidth: '1000px'
      }}
      onDrop={(event) => dropFunction(event)}
      onDragStart={(event) => DragStartFun(event)}
      onDragLeave={(event) => DragLeaveFun(event)}
      onDragOver={(event) => DragStartFun(event)}
    >
      <Box
        component="div"
        sx={{ mt: '40px', mb: '60px', p: '0 25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Button
          onClick={() => dispatch(clearStore())}
          color="secondary"
          startIcon={<KeyboardBackspaceIcon />}
          sx={
            middle
              ? { position: 'absolute', left: '15px', top: '135px', color: '#065176' }
              : { position: 'relative', color: '#065176', mb: '15px', width: '280px' }
          }
        >
          {translate('bgRemove.image_array_cards.back_button')}
        </Button>
        <input ref={fileInput} type="file" onChange={handleFileUpload} style={{ display: 'none' }} />
        <Button
          variant="contained"
          startIcon={<AddPhotoAlternateIcon />}
          sx={mobile ? { width: '400px' } : { width: '280px', m: '0 auto' }}
          size="large"
          onClick={() => fileInput.current.click()}
        >
          {dropCheck
            ? translate('bgRemove.image_array_cards.release_file')
            : translate('bgRemove.starter_component.upload.upload_text')}
        </Button>
        {error ? (
          <Box
            component="div"
            sx={{ pt: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => dispatch(delError())}
          >
            <ReportIcon color="error" sx={{ width: '20px', height: '20px', mr: '6px' }} />
            <Typography variant="body2" sx={{ color: '#065176', fontSize: '12px', maxWidth: '360px' }}>
              {errorMessage}
            </Typography>
          </Box>
        ) : (
          <Box component="div" sx={{ pt: '12px', textAlign: 'center' }}>
            <Typography variant="body2" sx={{ color: '#065176', fontSize: '12px' }}>
              {translate('bgRemove.starter_component.upload.sub_upload_text_part1')}
              {isFirefox ? '. ' : ','}
            </Typography>
            {!isFirefox && (
              <Typography variant="body2" sx={{ color: '#065176', fontSize: '12px' }}>
                {translate('bgRemove.starter_component.upload.sub_upload_text_part2')}{' '}
                <Box
                  onClick={urlHandler}
                  component="span"
                  sx={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  URL
                </Box>
                <Box
                  component="span"
                  sx={{
                    border: '1px solid rgba(6,8,118,0.18)',
                    borderRadius: '5px',
                    fontSize: '10px',
                    p: '5px',
                    ml: '10px'
                  }}
                >
                  CTRL
                </Box>
                <Box component="span" sx={{ ml: '3px', mr: '3px' }}>
                  +
                </Box>
                <Box
                  component="span"
                  sx={{ border: '1px solid rgba(6,8,118,0.18)', borderRadius: '5px', fontSize: '10px', p: '5px' }}
                >
                  V
                </Box>
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {isLoading ? <CircularProgress /> : <CardElem data={image} />}{' '}
    </Box>
  );
}
