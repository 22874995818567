import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { Button, TextField, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { closeModalUrl, removeBgFromURL } from '../../redux/slices/demoBGR';
import useLocales from '../../hooks/useLocales';

export default function ModalUrl() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { modalUrlAdd, browserSupportsCtrlV } = useSelector((state) => state.demoBGR);
  const [value, setValue] = React.useState('');
  const mobile = useMediaQuery('@media (min-width:600px)');
  const [isUrlValid, setIsUrlValid] = React.useState(false);

  useEffect(() => {
    const url = value;
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    if (reg.test(url)) {
      setIsUrlValid(true);
    } else {
      setIsUrlValid(false);
    }
  }, [value]);

  const handleClose = () => {
    dispatch(closeModalUrl());
    setValue('');
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      buttonClick();
    }
  };

  const buttonClick = () => {
    if (isUrlValid) {
      dispatch(removeBgFromURL(value, 'ru'));
      dispatch(closeModalUrl());
      setValue('');
    }
  };

  return (
    <Dialog open={modalUrlAdd} onClose={handleClose}>
      {browserSupportsCtrlV === false && (
        <Typography sx={{ px: 3, pt: 3 }}>{translate('bgRemove.browser_not_suppoting_auto_paste')}</Typography>
      )}
      <Box component="div" sx={{ pt: 2, pb: 2, pl: 2, pr: 2, display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          label={translate('general.input')}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          sx={{ width: mobile ? '400px' : '250px', mr: 2 }}
        />
        <Button
          variant="contained"
          size="large"
          color={isUrlValid ? 'primary' : 'inherit'}
          onClick={buttonClick}
          disabled={!isUrlValid}
        >
          {translate('general.send')}
        </Button>
      </Box>
    </Dialog>
  );
}
