/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
const smoothScroll = (targetPosition = 0) => {
  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth'
  });

  return new Promise((resolve, reject) => {
    const failed = setTimeout(() => {
      reject();
    }, 500);
  });

  const scrollHandler = () => {
    if (window.pageYOffset === targetPosition) {
      window.removeEventListener('scroll', scrollHandler);
      clearTimeout(failed);
      resolve();
    }
  };

  if (window.pageYOffset === targetPosition) {
    clearTimeout(failed);
    resolve();
  } else {
    window.addEventListener('scroll', scrollHandler);
  }
};

export default smoothScroll;
