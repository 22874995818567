/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import React from 'react';

import { useSearchParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Stack, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { useDispatch } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
import { sendResetPasswordAction } from '../../../redux/slices/user';

// Redux
// import { useDispatch, useSelector } from '../../../redux/store.ts';

// import { handleChangePassword } from '../../../redux/slices/auth';

// Redux
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
// import useIsMountedRef from '../../../hooks/useIsMountedRef';
// ----------------------------------------------------------------------

export default function ResetPasswordForm({ loading }) {
  // token взятый с url
  // const { jwt } = useParams();
  const [searchParams] = useSearchParams();
  // token взятый с url

  // const { user, error } = useSelector((store) => store.auth);

  // Состояние кнопки Показать/Скрыть пароль
  const [showPassword, setShowPassword] = React.useState(false);
  // Состояние кнопки Показать/Скрыть пароль

  // Состояние запроса
  const [setIsRequested] = React.useState(false);
  // Состояние запроса

  // Язык
  const { translate } = useLocales();
  // Язык

  const dispatch = useDispatch();

  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, translate('reset_password.passwordMinLength'))
      .required(translate('reset_password.passwordIsRequired'))
  });

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: NewPasswordSchema
  });

  const { dirty, values, errors, isValid, touched, getFieldProps } = formik;

  // Смена пароля
  const handleChangeNewPassword = (e) => {
    e.preventDefault();
    setIsRequested(true);
    const token = searchParams.get('jwt');
    localStorage.setItem('access_token', token);
    dispatch(sendResetPasswordAction(values.password));
  };
  // Смена пароля

  // useEffect(() => {
  //   console.log(searchParams.get('jwt'));
  // }, []);

  // React.useEffect(() => {
  //   if (user !== undefined) {
  //     setIsRequested(false);
  //     enqueueSnackbar(translate('reset_password.snackbarSucces'), { variant: 'success' });
  //     navigate(PATH_DASHBOARD.root);
  //   }
  //   if (error) {
  //     enqueueSnackbar(translate('reset_password.snackbarError'), { variant: 'error' });
  //   }
  // }, [user, error]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleChangeNewPassword} noValidate>
        <Stack direction="row" sx={{ width: '100%' }} component="div">
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate('reset_password.labelPassword')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <LoadingButton
          disabled={!isValid || !dirty}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{ mt: 3 }}
        >
          {translate('reset_password.saveButton')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
