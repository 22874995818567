/* eslint-disable react/prop-types */
import { Form, FormikProvider, useFormik } from 'formik';

// material
import { TextField, Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import React, { useState } from 'react';
// utils
import useLocales from '../../../hooks/useLocales';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm({ setSent }) {
  const { translate } = useLocales();

  const [error, setError] = useState(false);

  const forgotPasswordScheme = Yup.object().shape({
    email: Yup.string()
      .email(translate('validationsMessages.emailValid'))
      .required(translate('validationsMessages.emailRequired'))
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordScheme,
    onSubmit: async (values) => {
      try {
        await axios.post('/api/auth/forgot-password', { email: values.email });
        setSent(true);
      } catch (e) {
        setError(true);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>} */}

          {error && (
            <Alert sx={{ mb: 0.5 }} severity="error" onClick={() => setError(false)}>
              {translate('forgotPassword.notExist')}
            </Alert>
          )}

          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            label={translate('forgotPassword.emailHint')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {translate('forgotPassword.button_reset_title')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
