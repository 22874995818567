/* eslint-disable react/no-unused-prop-types */
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// material
import { alpha } from '@material-ui/core/styles';
import { MenuItem, ListItemText, Box, Typography } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@material-ui/styles';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import MenuPopover from '../../MenuPopover';
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------
LanguagePopoverFooter.propTypes = {
  sx: PropTypes.object
};

const useStyles = makeStyles(() => ({
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F6F8',
    cursor: 'pointer'
  }
}));

export default function LanguagePopoverFooter() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLang(value);
    handleClose();

    if (value === 'ru') {
      const path = window.location.pathname.split('/').pop();
      navigate(`/ru/${path}`);
    } else {
      const path = window.location.pathname.split('/').pop();
      navigate(`/en/${path}`);
    }
  };
  return (
    <>
      <Box className={classes.buttonBox}>
        <MIconButton
          disableRipple
          style={{ backgroundColor: 'transparent' }}
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            margin: 2,
            padding: 2,
            border: 1,
            borderRadius: '30px',
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
            })
          }}
        >
          <LanguageIcon sx={{ fill: (theme) => theme.palette.primary.main, mr: 1 }} />
          <Typography variant="body1" component="span" sx={{ color: (theme) => theme.palette.text, mr: 1 }}>
            {currentLang.label}
          </Typography>
          <ArrowForwardIosIcon />
        </MIconButton>
      </Box>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
        {allLang.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            sx={{ py: 1, px: 2.5 }}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
