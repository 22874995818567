/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/core/Alert';
import useLocales from '../../hooks/useLocales';

import LoginForm from './LoginForm';
import LoginGoogle from '../authentication/login/LoginGoogle';
import LoginVK from '../authentication/login/LoginVK';
import LoginYandex from '../authentication/login/LoginYandex';

export default function ModalLogin({ swipeForm }) {
  const { translate } = useLocales();
  const { isLoading, notification } = useSelector((state) => state.user);

  return (
    <Box component="div">
      <Box component="div" sx={{ mb: 1.5 }}>
        {notification.isNotification && (
          <Alert sx={{ mb: 0.5 }} severity={notification.notificationType}>
            {notification.notificationMessage}
          </Alert>
        )}
        <Typography variant="h5" align="center" sx={{ mb: 1 }}>
          {translate('login_page.log_in_with_help')}
        </Typography>
        <Box
          component="div"
          sx={{ display: 'flex', justifyContent: 'space-around', margin: '0 auto', maxWidth: '200px' }}
        >
          <LoginGoogle />
          <LoginVK />
          <LoginYandex />
        </Box>
      </Box>
      <Box component="div">
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
          {translate('login_page.log_in_with_picpack')}
        </Typography>
        <Box component="div" sx={{ mb: 2 }}>
          <LoginForm loading={isLoading} />
        </Box>
        <Box component="div">
          <Typography variant="body2">
            {translate('login_page.have_account')}{' '}
            <Button align="center" variant="text" onClick={swipeForm}>
              {translate('page_register.register')}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
