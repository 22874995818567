import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useLocales from '../hooks/useLocales';

import LoadingScreen from '../components/LoadingScreen';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import MainPage from '../pages/MainPage';
import DemoRU from '../pages/DemoRU';
import LogoOnlyLayoutDes from '../layouts/LogoOnlyLayoutDes';
import FaqsHero from '../pages/specialPages/PageFAQ';
import ForgotPassword from '../pages/authentication/ForgotPassword';
import ResetPassword from '../pages/authentication/ResetPassword';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  const isApp = pathname.includes('/app');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...((!isDashboard || isApp) && {
              // в перспективе необходимо будет убрать isDashboard
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { currentLang } = useLocales();
  return useRoutes([
    {
      path: `${currentLang.value}`,
      children: [
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '', element: <Navigate to="bgRemove" replace /> },
            { path: 'bgRemove', element: <MainPage /> }
          ]
        },
        {
          path: '*',
          element: <LogoOnlyLayoutDes />,
          children: [
            { path: 'image_desc', element: <DemoRU /> },
            { path: 'image_desc', element: <DemoRU /> }
          ]
        },
        {
          path: '*',
          children: [
            // { path: '500', element: <Page500 /> },
            { path: 'forgot', element: <ForgotPassword /> },
            { path: 'reset_password/:token', element: <ResetPassword /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="404" replace /> },
            { path: 'FAQ', element: <FaqsHero /> }
          ]
        }
      ]
    },
    // {
    //   path: '*',
    //   element: <LogoOnlyLayoutDes />,
    //   children: [
    //     { path: 'demo_en', element: <DemoRU /> },
    //     { path: 'demo_ru', element: <DemoRU /> }
    //   ]
    // },
    // {
    //   path: '*',
    //   children: [
    //     // { path: '500', element: <Page500 /> },
    //     { path: 'forgot', element: <ForgotPassword /> },
    //     { path: 'reset_password/:token', element: <ResetPassword /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //     { path: 'FAQ', element: <FaqsHero /> }
    //   ]
    // },
    { path: '', element: <Navigate to={`${currentLang.value}/bgRemove`} replace /> },
    { path: '*', element: <Navigate to={`${currentLang.value}/404`} replace /> }
  ]);
}

// const Demo = Loadable(lazy(() => import('../pages/MainPage')));
// const Page500 = Loadable(lazy(() => import('../pages/specialPages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/specialPages/Page404')));
// const NotFound = lazy(() => import('../pages/specialPages/Page404'));
