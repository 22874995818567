import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { closeModalAuth, clearNotification } from '../../redux/slices/user';
import ModalReg from './ModalReg';
import ModalLogin from './ModalLogin';

export default function ModalAuth() {
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector((state) => state.user);
  const [loginSwipe, setLoginSwipe] = useState(true);

  const handleClose = () => {
    dispatch(closeModalAuth());
  };

  const swipeForm = () => {
    setLoginSwipe(!loginSwipe);
    dispatch(clearNotification());
  };

  return (
    <Dialog open={isOpenModal} onClose={handleClose}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box component="div" sx={{ pt: 2.5, pb: 2.5, pl: 3.5, pr: 3.5 }}>
        {loginSwipe ? <ModalLogin swipeForm={swipeForm} /> : <ModalReg swipeForm={swipeForm} />}
      </Box>
    </Dialog>
  );
}
