import { createSlice } from '@reduxjs/toolkit';
// utils
import ym from 'react-yandex-metrika';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  errorMessage: '',
  errorForm: false,
  successForm: false,
  image: null,
  sources: '',
  lang: 'ru',
  modalUrlAdd: false,
  browserSupportsCtrlV: undefined
};

const slice = createSlice({
  name: 'demoBGR',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = true;

      switch (action.payload.code) {
        case 'file-invalid-type':
          state.errorMessage =
            window.navigator.language === 'ru'
              ? 'Извините, загруженный файл не поддерживается. Пожалуйста, загрузите файл в формате JPEG или PNG'
              : 'Sorry, the uploaded file is not supported. Please upload a JPEG or PNG file';
          break;
        case 'file-too-large':
          state.errorMessage =
            window.navigator.language === 'ru'
              ? 'Извините, размер файла должен быть меньше 15 МБ'
              : 'Sorry, the file size must be less than 15 MB';
          break;
        case 'url-didnt-load':
          state.errorMessage =
            window.navigator.language === 'ru' ? 'Не удалось загрузить картинку' : 'Failed to upload picture';
          break;
        case 'url-didnt-valid':
          state.errorMessage =
            window.navigator.language === 'ru' ? 'Не правильный url картинки' : 'Image url is not correct';
          break;
        default:
          state.errorMessage = window.navigator.language === 'ru' ? 'Неизвестный тип ошибки' : 'Unknown error type';
          break;
      }
    },
    delError(state) {
      state.error = false;
    },
    alertErrorClose(state) {
      state.errorForm = false;
    },
    alertSuccessClose(state) {
      state.successForm = false;
    },
    hasAlertError(state) {
      state.errorForm = true;
    },
    hasAlertSuccess(state) {
      state.successForm = true;
    },
    changeLang(state, action) {
      state.lang = action.payload;
    },
    clearStore(state) {
      state.image = null;
      state.error = false;
    },
    changeRatingStore(state, action) {
      state.image.rating = action.payload.rating;
    },
    addNewElement(state, action) {
      const { src, resolution, download } = action.payload.data;
      const id = action.payload.data.image_uuid;
      const { url } = action.payload;
      state.image = {
        image_uuid: id,
        sourceImg: url,
        sourceImgBG: src,
        download,
        extension: resolution,
        rating: 0
      };
    },
    openModalUrl(state, action) {
      if (action.payload) {
        const { supportsCtrlV } = action.payload;
        state.browserSupportsCtrlV = supportsCtrlV;
      }
      state.modalUrlAdd = true;
    },
    closeModalUrl(state) {
      state.modalUrlAdd = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getMorePosts,
  delError,
  startLoading,
  endLoading,
  deleteCurrentCard,
  hasError,
  alertErrorClose,
  alertSuccessClose,
  clearStore,
  changeLang,
  openModalUrl,
  closeModalUrl
} = slice.actions;

// ----------------------------------------------------------------------

export function removeBgFromURL(imageUrl) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.delError());
    ym('reachGoal', 'setFileBg', { awesomeParameter: 88092630 });
    try {
      const response = await axios.post(`/api/demo/removebg/url`, {
        imageUrl
      });
      dispatch(slice.actions.addNewElement({ data: response.data, url: imageUrl }));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ code: 'url-didnt-load', message: 'Не удалось загрузить картинку' }));
      dispatch(slice.actions.endLoading());
    }
  };
}

export function removeBgFromFileImg(file) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.delError());
    ym('reachGoal', 'getFileBg', { awesomeParameter: 88092630 });
    try {
      const data = new FormData();
      data.append('image_file', file);
      const response = await axios.post('/api/demo/removebg/file', data);
      dispatch(slice.actions.addNewElement({ data: response.data, url: URL.createObjectURL(file) }));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError({ code: 'url-didnt-load', message: 'Не удалось загрузить картинку' }));
      dispatch(slice.actions.endLoading());
    }
  };
}

export function sendEmailContactBgRemove(email, lang) {
  return async (dispatch) => {
    try {
      await axios.post('/api/demo/removebg/contact', {
        email,
        lang: lang || 'ru'
      });
      dispatch(slice.actions.hasAlertSuccess());
    } catch (error) {
      if (error.message === 'Request failed with status code 501') {
        dispatch(slice.actions.hasAlertSuccess());
      } else {
        dispatch(slice.actions.hasAlertError());
      }
    }
  };
}

export function changeRatingRB(rating) {
  return async (dispatch, getState) => {
    const { image } = getState().demoBGR;
    console.log(image.image_uuid, rating);
    try {
      await axios.post('/api/demo/removebg/rating', { image_uuid: image.image_uuid, rating });
      dispatch(slice.actions.changeRatingStore({ rating }));
    } catch (error) {
      console.log(error);
    }
  };
}
