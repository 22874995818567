import React, { useEffect, useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ReportIcon from '@mui/icons-material/Report';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import greenArrow from '../../customImages/Path.png';
import before from '../../customImages/girlBefo.png';
import 'react-before-after-slider-component/dist/build.css';
import UploadSingleFileBGR from '../upload/UploadSingleFileBGR';
import { useDispatch, useSelector } from '../../redux/store';
import { delError } from '../../redux/slices/demoBGR';
import useLocales from '../../hooks/useLocales';

// const FIRST_IMAGE = {
//   imageUrl: after
// };
// const SECOND_IMAGE = {
//   imageUrl: before
// };

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(() => ({
  wrapperAll: {
    width: '100%',
    marginTop: '12px',
    '& img': {
      height: '300px',
      width: '100%',
      objectFit: 'cover',
      objectPosition: '0 50%'
    }
  },
  arrow: {
    '&::before': {
      content: `url(${greenArrow})`,
      position: 'absolute',
      width: '40px'
    }
  },
  wrapperUrl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    display: 'block'
  }
}));

export default function StartedComponent() {
  const { error, errorMessage, isLoading } = useSelector((state) => state.demoBGR);
  const { translate } = useLocales();
  const mobile = useMediaQuery('@media (min-width: 640px)');
  const tablet = useMediaQuery('@media (min-width: 850px)');
  const dispatch = useDispatch();
  const linkEl = useRef(null);

  useEffect(() => {
    if (error) {
      linkEl.current?.scrollIntoView();
    }
  }, [error]);

  return (
    <Box
      component="div"
      sx={{ maxWidth: '956px', m: '0 auto', pb: '120px', pl: '30px', pr: '30px' }}
      style={
        mobile && !tablet
          ? { paddingLeft: '50px', paddingRight: '50px' }
          : { paddingLeft: '30px', paddingRight: '30px' }
      }
    >
      <Typography variant="h3" sx={{ mb: 1 }}>
        {translate('bgRemove.starter_component.title')}
        <Box
          component="img"
          src={greenArrow}
          sx={
            mobile
              ? { display: 'inline-block', ml: 2, position: 'absolute', mt: '5px' }
              : { display: 'inline-block', ml: 0, position: 'absolute' }
          }
        />
      </Typography>
      <Typography variant="body2" sx={{ mb: 5, maxWidth: '490px', color: '#637381' }}>
        {translate('bgRemove.starter_component.sub_title')}
      </Typography>
      {isLoading ? (
        <Box
          component="div"
          sx={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6}>
            <Box
              component="img"
              src={before}
              sx={{ height: '300px', width: '100%', borderRadius: '20px', objectFit: 'cover' }}
            />
            {/* <ReactBeforeSliderComponent firstImage={FIRST_IMAGE} secondImage={SECOND_IMAGE} /> */}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: '300px' }}>
            {error ? (
              <Box
                ref={linkEl}
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '300px',
                  borderRadius: '20px',
                  backgroundImage: `url(
                    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23065176FF' stroke-width='4' stroke-dasharray='17%2c 17' stroke-dashoffset='42' stroke-linecap='square'/%3e%3c/svg%3e"
                  )`
                }}
              >
                <Box component="div" sx={{ maxWidth: '300px', margin: '50px' }}>
                  <Typography variant="h6" sx={{ mb: '12px' }}>
                    {translate('general.error')}
                    <ReportIcon
                      color="error"
                      sx={{ width: '24px', height: '24px', position: 'absolute', ml: '6px', mt: '3px' }}
                    />
                  </Typography>
                  <Typography variant="body2" sx={{ mb: '24px' }}>
                    {errorMessage}
                  </Typography>
                  <Button onClick={() => dispatch(delError())} variant="contained">
                    {translate('general.try_again')}
                  </Button>
                </Box>
              </Box>
            ) : (
              <UploadSingleFileBGR />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
