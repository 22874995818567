/* eslint-disable react-hooks/exhaustive-deps */
// yandex-metrica
import { YMInitializer } from 'react-yandex-metrika';
// routes
import TagManager from 'react-gtm-module';
import React, { useEffect } from 'react';
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import './index.css';

// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import ModalAuth from './components/modalAuth/ModalAuth';
import { getDataUserAction } from './redux/slices/user';
import { useDispatch } from './redux/store';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const tagManagerArgs = {
    gtmId: 'GTM-P8WMKS6'
  };
  if (process.env.NODE_ENV === 'production') {
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    dispatch(getDataUserAction());
  }, []);

  return (
    <ThemeConfig>
      <YMInitializer accounts={[88092630]} />
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ScrollToTop />
              <Router />
              <ModalAuth />
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
