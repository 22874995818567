/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Stack from '@material-ui/core/Stack';
import Alert from '@material-ui/core/Alert';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@iconify/react';
import LoadingButton from '@material-ui/lab/LoadingButton';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import useLocales from '../../hooks/useLocales';
import { registrationAction } from '../../redux/slices/user';
import LoginGoogle from '../authentication/login/LoginGoogle';
import LoginVK from '../authentication/login/LoginVK';
import LoginYandex from '../authentication/login/LoginYandex';

export default function ModalReg({ swipeForm }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { isLoading, notification } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, translate('page_register.short_error'))
      .max(50, translate('page_register.long_error'))
      .required(translate('page_register.first_name_error')),
    lastName: Yup.string()
      .min(1, translate('page_register.short_error'))
      .max(50, translate('page_register.long_error'))
      .required(translate('page_register.last_name_error')),
    email: Yup.string()
      .email(translate('page_register.email_valid_error'))
      .required(translate('page_register.email_error')),
    password: Yup.string()
      .min(1, translate('page_register.password_short_error'))
      .max(64, translate('page_register.password_long_error'))
      .required(translate('page_register.password_error'))
      .test(
        'isValid',
        () => `${translate('page_register.password_no_valid')}`,
        (value) => /[a-zA-Z]{4}/.test(value) && /\d+/.test(value)
      )
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (e) => {
      dispatch(registrationAction(e));
    }
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box component="div">
      <Box component="div">
        {notification.isNotification && (
          <Alert sx={{ mb: 0.5 }} severity={notification.notificationType}>
            {notification.notificationMessage}
          </Alert>
        )}
        <Typography variant="h5" align="center" sx={{ mb: 1 }}>
          {translate('page_register.register_and_login_with_social_networks')}
        </Typography>
        <Box
          component="div"
          sx={{ display: 'flex', justifyContent: 'space-around', margin: '0 auto', maxWidth: '200px', mb: 2 }}
        >
          <LoginGoogle />
          <LoginVK />
          <LoginYandex />
        </Box>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  disabled={false}
                  label={translate('page_register.first_name')}
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  disabled={false}
                  label={translate('page_register.last_name')}
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>

              <TextField
                fullWidth
                disabled={false}
                autoComplete="username"
                type="email"
                label={translate('page_register.email')}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                disabled={false}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label={translate('page_register.password')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleShowPassword}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                disabled={isLoading}
              >
                {translate('page_register.register')}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
        <Box component="div" sx={{ mt: 3 }}>
          <Typography align="center" variant="body2">
            {translate('page_register.already_have_account')}{' '}
            <Button variant="text" onClick={swipeForm}>
              {translate('login_page.login')}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
