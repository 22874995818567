import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Button from '@material-ui/core/Button';
import useLocales from '../../hooks/useLocales';

export default function OurProductsDemo() {
  const { translate } = useLocales();

  return (
    <Box component="div" sx={{ backgroundColor: '#F4F6F8' }}>
      <Box component="div" sx={{ m: '0 auto', maxWidth: '875px', p: '60px 35px', backgroundColor: '#F4F6F8' }}>
        <Typography align="center" variant="h3" sx={{ mb: '46px' }}>
          {translate('pricing.our_products.title')}
        </Typography>
        <Typography variant="h6" sx={{ mb: '12px' }}>
          {translate('pricing.our_products.sub_title')}
        </Typography>
        <Typography variant="body2" sx={{ mb: '24px', color: '#637381' }}>
          {translate('pricing.our_products.text')}
        </Typography>
        <Box component="div" sx={{ textAlign: 'right' }}>
          <Button href="bgRemove" endIcon={<ArrowRightAltIcon />}>
            {translate('pricing.our_products.transition')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
