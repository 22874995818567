export default [
  {
    id: 'custom',
    subscription: 'pricing.cards.titles.firstCardTitle',
    icon: 'CUSTOM',
    price: null,
    discount: false,
    lists: [
      { text: 'pricing.cards.lists.firstCardList.item1', isAvailable: true },
      { text: 'pricing.cards.lists.firstCardList.item2', isAvailable: true },
      { text: 'pricing.cards.lists.firstCardList.item3', isAvailable: true }
    ],
    labelAction: 'pricing.cards.buttons.firstCardButton',
    isSpecial: false,
    customPlan: true
  },
  {
    id: 'starter',
    subscription: 'pricing.cards.titles.secondCardTitle',
    icon: 'STARTER',
    popular: 'pricing.cards.popular',
    price: 1000,
    discount: true,
    lists: [
      { text: 'pricing.cards.lists.secondCardList.item1', isAvailable: true },
      { text: 'pricing.cards.lists.secondCardList.item2', isAvailable: true },
      { text: 'pricing.cards.lists.secondCardList.item3', isAvailable: true },
      { text: 'pricing.cards.lists.secondCardList.item4', isAvailable: true },
      { text: 'pricing.cards.lists.secondCardList.item5', isAvailable: true }
    ],
    labelAction: 'pricing.cards.buttons.secondCardButton',
    isSpecial: false,
    customPlan: false
  },
  {
    id: 'premium',
    subscription: 'pricing.cards.titles.thirdCardTitle',
    icon: 'PREMIUM',
    price: 10000,
    discount: true,
    lists: [
      { text: 'pricing.cards.lists.thirdCardList.item1', isAvailable: true },
      { text: 'pricing.cards.lists.thirdCardList.item2', isAvailable: true },
      { text: 'pricing.cards.lists.thirdCardList.item3', isAvailable: true },
      { text: 'pricing.cards.lists.thirdCardList.item4', isAvailable: true },
      { text: 'pricing.cards.lists.thirdCardList.item5', isAvailable: true },
      { text: 'pricing.cards.lists.thirdCardList.item6', isAvailable: true }
    ],
    labelAction: 'pricing.cards.buttons.thirdCardButton',
    isSpecial: false,
    customPlan: false
  }
];
