import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

import logo from '../customImages/LogoDemo.svg';

// Styles
import styles from './Logo.module.css';
// Styles

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

function Logo({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: '180px', ...sx }}>
      <img src={logo} alt="Logo" className={styles.logo} />
    </Box>
  );
}

export default Logo;
