/* eslint-disable react-hooks/exhaustive-deps */
import { isString } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UploadIllustration } from '../../assets';
import useLocales from '../../hooks/useLocales';
import { allowedFileFormats } from '../../configs';
import { useDispatch } from '../../redux/store';
import { clearStore, getDescriptionFromFileImg, hasError } from '../../redux/slices/demoDes';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: `none`,
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  /* padding: theme.spacing(5, 0), */
  height: '430px',
  borderRadius: '16px',
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `2px dashed ${theme.palette.grey[500_32]}`,
  margin: '16px',
  '&:hover': {
    opacity: 0.8,
    cursor: 'pointer'
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadSingleFileDes.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object
};

export default function UploadSingleFileDes({ error, sx, ...other }) {
  const [file, setFile] = useState(null);
  const { sources, sourcesUrl, lang } = useSelector((state) => state.demoDes);
  const [rejected, setRejected] = useState(false);

  const { translate } = useLocales();

  const dispatch = useDispatch();
  const onDrop = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFile) {
      dispatch(clearStore());
      setFile({
        ...acceptedFile,
        preview: URL.createObjectURL(acceptedFile)
      });
      dispatch(getDescriptionFromFileImg(acceptedFile, lang));
      // } else {
      //   const errors = functionReturnErrors();
      //   console.log(errors);
      //   if (errors.length > 0) {
      //     dispatch(hasError(errors[0].errors[0]));
      //   }
    }
    // getDescriptionFromFileImg
  }, []);

  const bytesPerMegabyte = 1048576;
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept: allowedFileFormats.map((format) => `.${format}`).join(','),
    maxSize: bytesPerMegabyte * 15,
    onDrop,
    ...other
  });

  // очищает список некорректных файлов
  useEffect(() => {
    if (rejected) {
      fileRejections.splice(0, fileRejections.length);
      setRejected(false);
    }
    if (fileRejections[0]) {
      if (fileRejections[0].errors[0]) {
        dispatch(hasError(fileRejections[0].errors[0]));
      }
    }
  }, [rejected, fileRejections]);

  // @todo
  // определет текст ошибки
  // function getErrorMessage(error) {
  //   const { code } = error;
  //   if (code === 'file-invalid-type') {
  //     return translate('errors.unsupported_file_format_message');
  //   }
  //   if (code === 'file-too-large') {
  //     return translate('errors.too_large_file_message');
  //   }
  //   return translate('errors.unknown_error');
  // }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main'
          }),
          ...(file && { padding: '12% 0' })
        }}
      >
        <input {...getInputProps()} />
        <UploadIllustration sx={{ width: 220 }} pl={2} />
        {!isDragReject && (
          <Box sx={{ p: 3, ml: { md: 2 } }}>
            <Typography gutterBottom variant="h5">
              {translate('upload.drop_select')}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('upload.drop_click')}&nbsp;
              <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                {translate('upload.browse')}
              </Typography>
              &nbsp;{translate('upload.machine')}
            </Typography>
          </Box>
        )}
        {sourcesUrl && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
              bgcolor: '#F4F6F8'
            }}
          />
        )}
        {file && sourcesUrl && sources.length < 1 && (
          <Box
            component="img"
            alt="file preview"
            src={isString(file) ? file : file.preview}
            sx={{
              top: 8,
              objectFit: 'contain',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)'
            }}
          />
        )}
        {sources.length > 1 && sourcesUrl && (
          <Box
            component="img"
            alt="file preview"
            src={sources}
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: 'contain',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)'
            }}
          />
        )}
        {/* {isLoading && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0,0,0,0.5)',
              zIndex: '10'
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </DropZoneStyle>

      {/* {fileRejections.length > 0 && ( */}
      {/*  <ModalUniversal */}
      {/*    title={translate('general.error')} */}
      {/*    errorText={getErrorMessage(fileRejections[0].errors[0])} */}
      {/*    isOpen */}
      {/*    onCloseModal={() => setRejected(true)} */}
      {/*  /> */}
      {/* )} */}
    </Box>
  );
}
