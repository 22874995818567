import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Link as LinkRoute } from 'react-router-dom';
import { useDispatch, useSelector } from '../redux/store';
import StarterComponent from '../components/bgRemove/StartedComponent';
import TryItYourself from '../components/bgRemove/TryItYourself';
import Subscribe from '../components/bgRemove/Subscribe';
import ImageCards from '../components/bgRemove/ImageCards';
import { hasError, openModalUrl, removeBgFromURL } from '../redux/slices/demoBGR';
import Pricing from '../components/Pricing';
import WhatWeCan from '../components/bgRemove/WhatWeCan';
import ModalUrl from '../components/bgRemove/ModalUrl';
import useLocales from '../hooks/useLocales';
import LanguagePopoverFooter from '../components/_demo/LanguagePopover/LanguagePopoverFooter';

export default function MainPage() {
  // const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const dispatch = useDispatch();
  const [lang] = React.useState(localStorage.getItem('lang'));
  const { image, modalUrlAdd } = useSelector((state) => state.demoBGR);
  const { isOpenModal } = useSelector((state) => state.user);
  const { translate, currentLang } = useLocales();

  useEffect(() => {
    // document.addEventListener('keydown', handleKeyDown, false);
  }, []);

  const handleKeyDown = async (e) => {
    const isFirefox = /firefox/.test(navigator?.userAgent?.toLowerCase() || null);

    if (isFirefox) {
      dispatch(openModalUrl({ supportsCtrlV: false }));
    }

    if (isFirefox && isOpenModal) return;
    if (modalUrlAdd) return;

    if (e.ctrlKey && (e.key === 'v' || e.key === 'м')) {
      await navigator?.clipboard
        ?.readText()
        .then((text) => {
          const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
          if (reg.test(text)) {
            // check validation
            dispatch(removeBgFromURL(text, lang));
          } else {
            dispatch(hasError({ code: 'url-didnt-valid', message: translate('bgRemove.error_loading_file') }));
          }
        })
        .catch(() => console.log('error'));
    }
  };

  return (
    <Box component="div" onKeyDown={handleKeyDown} tabIndex={0}>
      <ModalUrl />
      <Box component="div" sx={{ mt: image ? 0 : '200px' }}>
        {image ? (
          <ImageCards />
        ) : (
          <Box>
            <StarterComponent />
            <TryItYourself />
          </Box>
        )}
        <WhatWeCan />
        <Pricing />
        <Box component="div" id="earlyAccess">
          <Subscribe />
        </Box>
        <Box
          component="div"
          sx={{
            pb: 1,
            pt: 2,
            backgroundColor: '#F4F6F8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <LinkRoute to={`/${currentLang.value}/FAQ`} style={{ textAlign: 'center', color: '#637381' }}>
            FAQ
          </LinkRoute>
        </Box>

        <Box
          component="div"
          sx={{
            backgroundColor: '#F4F6F8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <LanguagePopoverFooter />
        </Box>

        <Box
          component="div"
          sx={{
            backgroundColor: '#F4F6F8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Link
            display="block"
            align="center"
            underline="none"
            sx={{ color: '#637381', pb: '20px' }}
            href={`${currentLang.value}` === 'ru' ? 'https://m-s-e-t.com/ru' : 'https://m-s-e-t.com/'}
            target="_blank"
          >
            © mind-set.ru
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
