/* eslint-disable react-hooks/exhaustive-deps */
import { styled, useTheme } from '@material-ui/core/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Grid, Container, Typography, Button } from '@material-ui/core';
import LinkM from '@material-ui/core/Link';
import { Link } from 'react-scroll';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/core/Rating';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { isString } from 'lodash';
import { Link as LinkRoute } from 'react-router-dom';
import Page from '../components/Page';
import Gallery from '../components/Gallery/Gallery';
import useLocales from '../hooks/useLocales';
import { useDispatch } from '../redux/store';
import PasteUrlDemoPage from '../components/pasteUrlDemo/PasteUrlDemoPage';
import { changeLang, changeRating, clearStore, delError, getDescriptionFromFileImg } from '../redux/slices/demoDes';
import macbook from '../customImages/macbook.png';
import card1 from '../customImages/card1.png';
import card2 from '../customImages/card2.png';
import card3 from '../customImages/card3.png';
import card4 from '../customImages/card4.png';
import PasteUrlDemoPageMobile from '../components/pasteUrlDemo/PasteUrlDemoPageMobile';
import SliderMobile from '../components/SliderMobile/SliderImgMobile';
import { mainProxy } from '../configs';
import UploadSingleFileDes from '../components/upload/UploadSingleFileDes';
import LanguagePopoverFooter from '../components/_demo/LanguagePopover/LanguagePopoverFooter';
import Pricing from '../components/Pricing';
import OurProductsDemo from '../components/_demo/OurProductsDemo';
import Subscribe from '../components/bgRemove/Subscribe';
import WhatWeCanSlider from '../components/bgRemove/WhatWeCanSlider';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(9)
  }
}));

const TagBox = styled('div')(
  `
  padding: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 10px;
  height: 130px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);
    border-radius: 15px;
  }
`
);

const proxy = mainProxy;

// ----------------------------------------------------------------------

export default function DemoRU() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const miniMobile = useMediaQuery('@media (min-width:400px)');
  const [file, setFile] = useState(null);
  const { currentLang, translate } = useLocales();
  const dispatch = useDispatch();
  const [ratingCheck, setRatingCheck] = useState(false);
  const { isAuth } = useSelector((state) => state.user);

  const { isLoading, errorMessage, description, tags, rating, sourcesUrl, sources, error } = useSelector(
    (state) => state.demoDes
  );

  useEffect(() => {
    localStorage.setItem('lang', 'ru');
    dispatch(changeLang('ru'));
  }, []);

  const onBack = () => {
    dispatch(clearStore());
    setFile(null);
  };

  const callBackSetFile = (fileCB, lang) => {
    setFile(URL.createObjectURL(fileCB));
    dispatch(getDescriptionFromFileImg(fileCB, lang));
  };

  const changeRatingFun = (number) => {
    if (number) {
      dispatch(changeRating(number, sourcesUrl));
      setRatingCheck(true);
      setTimeout(() => {
        setRatingCheck(false);
      }, 2500);
    }
  };

  const handleCloseModalDescription = () => {
    dispatch(delError());
    setFile(null);
  };

  // console.log('[{"tag": "auto", "confidence": 0.99}, {"tag": "road", "confidence": 0.78}]');
  // console.log(JSON.parse('[{"tag": "auto", "confidence": 0.99}, {"tag": "road", "confidence": 0.78}]'));

  if (mobile)
    return (
      <RootStyle title="Picpack">
        <Box component="div" sx={{ pt: 6, pb: 4, bgcolor: '#F4F6F8' }}>
          <Container maxWidth="lg">
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} md={6} align={matches ? 'left' : 'center'}>
                <Typography itemprop="headline" variant="h1" sx={{ mb: 3, fontSize: '30px!important' }}>
                  {translate('page_demo.title')}
                </Typography>
                <Typography itemprop="articleBody" variant="body2" sx={{ mb: 5, maxWidth: '490px', color: '#637381' }}>
                  {translate('page_demo.subtitle')}
                </Typography>
                <Link to="assay" smooth offset={-50} duration={300}>
                  <Button variant="contained" size="large">
                    {translate('general.try_out')}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={!matches && { display: 'none' }}>
                <Box component="img" src={macbook} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Typography mt={9} variant="h4" align="left" sx={{ mb: 1 }}>
            {translate('page_demo.page_image_description')}
          </Typography>
          <Box pb={5} component="div" id="assay">
            <Grid
              container
              order={{ xs: 2, md: 1 }}
              spacing={5}
              mb={5}
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              {sourcesUrl && !error && (
                <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
                  <Typography variant="h6" align="left" sx={{ mb: '10px' }}>
                    {translate('page_demo.page_image_description_generation_t1')}
                  </Typography>
                  <TagBox sx={{ height: '95px', mb: '20px' }}>
                    <Typography variant="body1" align="left" sx={{ mb: '10px' }}>
                      {description}
                    </Typography>
                  </TagBox>
                  <Typography variant="h6" align="left" sx={{ mb: '10px' }}>
                    {translate('page_demo.page_image_description_generation_t2')}
                  </Typography>

                  <TagBox sx={{ mb: '20px' }}>
                    {tags &&
                      tags.map((item, i) => (
                        <Chip key={i} color="primary" variant="outlined" label={item.tag} sx={{ margin: '2px' }} />
                      ))}
                  </TagBox>

                  <Typography variant="h6" align="left" sx={{ mb: '10px' }}>
                    {ratingCheck
                      ? translate('page_demo.page_image_description_generation_s')
                      : translate('page_demo.page_image_description_generation_t3')}
                  </Typography>
                  {ratingCheck ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      onChange={(e, newValue) => changeRatingFun(newValue)}
                    />
                  )}
                  <Box component="div" sx={{ mt: '20px' }}>
                    <Button onClick={onBack} variant="outlined">
                      <ArrowBackIosNewIcon sx={{ height: '16px' }} />
                      {translate('page_demo.page_image_description_generation_back')}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ ml: 2 }}
                      href={`${proxy}/api/demo/description/result?lang=ru&image_uuid=${sourcesUrl}`}
                    >
                      <FileDownloadIcon />
                      {translate('page_demo.page_image_description_generation_download')}
                    </Button>
                  </Box>
                </Grid>
              )}
              {!sourcesUrl && !error && (
                <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
                  <Typography variant="body2" align="left" sx={{ mb: '12px', color: '#637381' }}>
                    {translate('page_demo.page_image_description_heading')}
                  </Typography>
                  <Gallery />
                  <Typography variant="subtitle1" align="left" sx={{ mt: 3 }}>
                    {translate('page_demo.page_image_description_title_address')}
                  </Typography>
                  <PasteUrlDemoPage />
                </Grid>
              )}
              {error && (
                <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
                  <Box
                    component="div"
                    sx={{
                      width: '100%',
                      bgcolor: '#fff',
                      borderRadius: 2,
                      border: '2px solid #F9FAFB',
                      height: '218px',
                      zIndex: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      p: '34px',
                      boxShadow: '0px 0px 4px rgba(145, 158, 171, 0.24), 0px 24px 48px rgba(145, 158, 171, 0.24)'
                    }}
                  >
                    <Box component="div">
                      <Typography variant="h6" align="left" sx={{ mb: '12px' }}>
                        {translate('page_demo.modal_upload_img_title')}
                      </Typography>
                      <Typography variant="body2" align="left">
                        {errorMessage}
                      </Typography>
                    </Box>
                    <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant="contained" onClick={handleCloseModalDescription}>
                        {translate('page_demo.modal_upload_img_button')}
                      </Button>
                    </Box>
                  </Box>
                  <Typography variant="h6" align="left" sx={{ mb: 1, mt: 3 }}>
                    {translate('page_demo.page_image_description_generation_t3')}
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    disabled
                    value={rating}
                    onChange={(e, newValue) => changeRatingFun(newValue)}
                  />
                  <Box component="div" sx={{ mt: 2 }}>
                    <Button onClick={onBack} variant="outlined" sx={{ mb: 1 }} disabled>
                      <ArrowBackIosNewIcon sx={{ height: '16px' }} />
                      {translate('page_demo.page_image_description_generation_back')}
                    </Button>
                    <Button variant="contained" sx={{ ml: 2, mb: 1 }} disabled>
                      <FileDownloadIcon />
                      {translate('page_demo.page_image_description_generation_download')}
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={7} display="flex" alignItems="center" order={{ xs: 1, md: 2 }}>
                <Box
                  component="div"
                  sx={{ bgcolor: '#F4F6F8', borderRadius: '16px', position: 'relative', width: '100%' }}
                >
                  <UploadSingleFileDes sx={{ width: '100%' }} />
                  {isLoading && (
                    <Box
                      component="div"
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        borderRadius: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'rgba(0,0,0,0.5)',
                        zIndex: '10'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {translate('page_demo.card_title')}
          </Typography>
          <Typography variant="h6" sx={{ mb: '20px' }}>
            {translate('page_demo.order.title')}
            <Button href="mailto:info@picpack.io" variant="outlined" sx={{ ml: 2 }}>
              {translate('page_demo.order.button')}
            </Button>
          </Typography>
          <Grid container spacing={3} sx={{ mb: 7 }}>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card1} alt="card1" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">{translate('page_demo.page_card_1')}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card2} alt="card2" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">{translate('page_demo.page_card_2')}</Typography>
                </CardContent>
              </Card>
            </Grid>
            {matches && (
              <Grid item md={2} sm={4} xs={6}>
                <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                  <CardMedia component="img" height="156" image={card3} alt="card3" />
                  <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                    <Typography variant="subtitle2">{translate('page_demo.page_card_3')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card4} alt="card4" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">
                    {translate('page_demo.page_card_4')}
                    <LinkM underline="hover" href="https://t.me/picpack_bot">
                      Telegram bot
                    </LinkM>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {matches && (
              <Grid item md>
                <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                  <CardContent display="flex">
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      {translate('page_demo.page_card_5_title')}
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: '14px', lineHeight: '22px', color: '#637381' }}>
                      {translate('page_demo.page_card_5_main')}
                    </Typography>
                    <Link to="earlyAccess" smooth offset={50} duration={350}>
                      <Box sx={{ mt: 3 }}>
                        <Button variant="outlined">{translate('page_demo.page_card_5_button')}</Button>
                      </Box>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
        {isAuth ? <Pricing /> : ''}
        <Subscribe />
        <OurProductsDemo />
        <Box component="div" id="earlyAccess">
          <Box
            component="div"
            sx={{
              backgroundColor: '#F4F6F8',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2
            }}
          >
            <LinkRoute
              to={`/${currentLang.value}/FAQ`}
              style={{ display: 'block', textAlign: 'center', color: '#637381' }}
            >
              FAQ
            </LinkRoute>
          </Box>

          <Box
            component="div"
            sx={{
              backgroundColor: '#F4F6F8',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <LanguagePopoverFooter />
          </Box>

          <Box
            component="div"
            sx={{
              backgroundColor: '#F4F6F8',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pb: 2
            }}
          >
            <LinkM
              href={`${currentLang.value}` === 'ru' ? 'https://m-s-e-t.com/ru' : 'https://m-s-e-t.com/'}
              display="block"
              align="center"
              underline="none"
              sx={{ color: '#212B36', fontSize: '12px' }}
              target="_blank"
            >
              © mind-set.ru
            </LinkM>
          </Box>
        </Box>
      </RootStyle>
    );
  return (
    <RootStyle>
      <Box component="div" sx={{ pt: '100px', pb: '100px', bgcolor: '#F4F6F8' }}>
        <Container maxWidth="lg" sx={{ textAlign: 'center', p: '0 15px' }}>
          <Typography variant="h4" sx={{ pb: '20px', maxWidth: '300px', m: '0 auto' }}>
            {translate('page_demo.title')}
          </Typography>
          <Typography variant="body2" sx={{ pb: '30px', color: '#637381', maxWidth: '320px', m: '0 auto' }}>
            {translate('page_demo.subtitle')}
          </Typography>
          <Box component="img" src={macbook} sx={{ mb: '50px' }} />
          <Link to="assay" smooth offset={-50} duration={300}>
            <Button variant="contained" size="large">
              {translate('general.try_out')}
            </Button>
          </Link>
        </Container>
      </Box>
      <Container sx={{ p: '60px 15px' }}>
        <Box component="div">
          <Typography variant="h5" align="center" sx={{ pb: 2 }} id="assay">
            {translate('page_demo.page_image_description')}
          </Typography>
          {!sourcesUrl && !error && !isLoading && (
            <Box align="center">
              <Typography
                align="center"
                variant="body2"
                sx={{ pb: '30px', color: '#637381', maxWidth: '350px', margin: '0 auto' }}
              >
                {translate('page_demo.page_image_description_heading')}
              </Typography>
              <Box component="div" sx={{ mb: '30px' }}>
                <SliderMobile />
              </Box>
              <Typography align="center" variant="h6" sx={{ mb: '30px' }}>
                {translate('page_demo.mobile_choice_subtitle')}
              </Typography>
              <PasteUrlDemoPageMobile callBackSetFile={callBackSetFile} />
            </Box>
          )}
          {sourcesUrl && !error && !isLoading && (
            <Box>
              <Box
                component="div"
                sx={{
                  height: '400px',
                  bgcolor: '#F4F6F8',
                  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
                  borderRadius: 2,
                  mb: '30px',
                  p: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box
                  component="img"
                  src={isString(file) ? file : sources}
                  sx={{ objectFit: 'container', maxHeight: '380px' }}
                />
              </Box>
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                {translate('page_demo.page_image_description_generation_t1')}
              </Typography>
              <TagBox sx={{ height: '95px', mb: '30px' }}>
                <Typography variant="body1" align="left" sx={{ mb: '10px' }}>
                  {description}
                </Typography>
              </TagBox>
              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                {translate('page_demo.page_image_description_generation_t2')}
              </Typography>

              <TagBox sx={{ mb: '30px' }}>
                {tags &&
                  tags.map((item, i) => (
                    <Chip key={i} color="primary" variant="outlined" label={item.tag} sx={{ margin: '2px' }} />
                  ))}
              </TagBox>

              <Box component="div" sx={{ mb: '60px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onBack} variant="outlined">
                  <ArrowBackIosNewIcon sx={{ height: '16px' }} />
                  {translate('page_demo.page_image_description_generation_back')}
                </Button>
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  href={`${proxy}/api/demo/description/result?lang=ru&image_uuid=${sourcesUrl}`}
                >
                  <FileDownloadIcon />
                  {translate('page_demo.page_image_description_generation_download')}
                </Button>
              </Box>

              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                {ratingCheck
                  ? translate('page_demo.page_image_description_generation_s')
                  : translate('page_demo.page_image_description_generation_t3')}
              </Typography>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
                {ratingCheck ? (
                  <CheckIcon color="primary" />
                ) : (
                  <Rating
                    name="simple-controlled"
                    value={rating}
                    size="large"
                    onChange={(e, newValue) => changeRatingFun(newValue)}
                  />
                )}
              </Box>
            </Box>
          )}
          {error && (
            <Box
              component="div"
              sx={{
                width: '100%',
                bgcolor: '#F9FAFB',
                borderRadius: 2,
                border: '2px solid #F9FAFB',
                height: '218px',
                zIndex: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                p: 3,
                boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)'
              }}
            >
              <Box component="div">
                <Typography variant="h6" align="left" sx={{ mb: '12px' }}>
                  {translate('page_demo.modal_upload_img_title')}
                </Typography>
                <Typography variant="body2" align="left">
                  {errorMessage || translate('page_demo.mobile_error')}
                </Typography>
              </Box>
              <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" onClick={handleCloseModalDescription}>
                  {translate('page_demo.modal_upload_img_button')}
                </Button>
              </Box>
            </Box>
          )}
          {isLoading && (
            <Box
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '60px'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
      <Box component="div" sx={{ m: '0 auto', maxWidth: '1200px' }}>
        <Container sx={{ pt: '60px', pb: '60px', pl: '15px', pr: '15px' }}>
          <Typography align="center" variant="h4" sx={{ mb: '20px' }}>
            {translate('page_demo.card_title')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography lineHeight="2" align="center" variant="h6" sx={{ mb: '20px', fontWeight: '30px' }}>
              {translate('page_demo.order.title')}
            </Typography>
            <Button href="mailto:info@picpack.io" variant="outlined" sx={{ width: '100px', mb: '20px' }}>
              {translate('page_demo.order.button')}
            </Button>
          </Box>
          <WhatWeCanSlider />
          <Grid container spacing={1}>
            <Grid item md>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardContent display="flex">
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {translate('page_demo.page_card_5_title')}
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: '14px', lineHeight: '22px', color: '#637381' }}>
                    {translate('page_demo.page_card_5_main')}
                  </Typography>
                  <Link to="earlyAccess" smooth offset={50} duration={350}>
                    <Box sx={{ mt: 3 }}>
                      <Button variant="outlined">{translate('page_demo.page_card_5_button')}</Button>
                    </Box>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {isAuth ? <Pricing /> : ''}
      <Box component="div" id="earlyAccess">
        <Box component="div" sx={{ maxWidth: '600px', margin: '0 auto', pt: '50px' }}>
          <Subscribe />
        </Box>

        <OurProductsDemo />

        <Box
          component="div"
          sx={{
            backgroundColor: '#F4F6F8',
            pb: 1,
            pt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <LinkRoute
            to={`/${currentLang.value}/FAQ`}
            style={{ display: 'block', textAlign: 'center', color: '#637381' }}
          >
            FAQ
          </LinkRoute>
        </Box>

        <Box
          component="div"
          sx={{
            backgroundColor: '#F4F6F8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <LanguagePopoverFooter />
        </Box>

        <Box
          component="div"
          sx={{
            backgroundColor: '#F4F6F8',
            display: 'flex',
            flexDirection: 'column',
            pb: 2,
            alignItems: 'center'
          }}
        >
          <LinkM
            href={`${currentLang.value}` === 'ru' ? 'https://m-s-e-t.com/ru' : 'https://m-s-e-t.com/'}
            display="block"
            align="center"
            underline="none"
            sx={{ color: '#212B36', fontSize: '12px' }}
            target="_blank"
          >
            © mind-set.ru
          </LinkM>
        </Box>
      </Box>
    </RootStyle>
  );
}
