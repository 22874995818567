import i18next from 'i18next';
import { errorsConfig } from '../configs';

const errorsHandler = (...args) => {
  const [err] = args;
  const error = err?.response?.data || null;
  return i18next.t(
    errorsConfig.find((err) => error?.error === err.error)?.error
      ? `errors.${errorsConfig.find((err) => error.error === err.error)?.error}`
      : 'errors.unknown_error'
  );
};

export default errorsHandler;
