import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { clientIdYandex } from '../../../configs';
// import { loginPending } from '../../../../redux/slices/userGoogleYandexSlice';
import logoYandex from '../../../customImages/icon-yandex.png';
import { loginWithYandexAction } from '../../../redux/slices/user';

const useStyles = makeStyles(() => ({
  customBtn: {
    width: '45px',
    height: '45px',
    borderRadius: '50%',
    border: '1px solid #d4d4d4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#d4d4d4',
      cursor: 'pointer'
    }
  },
  icon: {
    width: '25px',
    height: '25px'
  }
}));

export default function LoginYandex() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginHandler = () => {
    const redirectTo = window?.location?.href || 'https://app.picpack.io';
    console.log(redirectTo);
    const YWindow = window.open(
      `https://oauth.yandex.ru/authorize?response_type=token&client_id=${clientIdYandex}&redirect_uri=${redirectTo}`,
      'caption',
      'resizable=1,width=450,height=650',
      true
    );

    // eslint-disable-next-line consistent-return
    const popupCheck = () => {
      if (YWindow.closed) {
        clearInterval(newInterval);
      } else {
        let url;
        try {
          url = YWindow.location.href.split('#');
        } catch (e) {
          return true;
        }
        if (url.length > 1) {
          const data = url[1].split('&');
          const objMain = {};
          for (let i = 0; i < data.length; i += 1) {
            const elem = data[i].split('=');
            objMain[elem[0]] = String(elem[1]);
          }
          if (objMain.access_token) {
            try {
              dispatch(loginWithYandexAction(objMain.access_token));
            } catch (e) {
              console.log(e);
            }
            YWindow.close();
          }
        }
      }
    };

    let newInterval = setInterval(popupCheck, 100);
  };

  return (
    <div>
      <Link className={classes.customBtn} onClick={loginHandler}>
        <img src={logoYandex} className={classes.icon} alt="logoYandex" />
      </Link>
    </div>
  );
}
