/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import useLocales from '../../hooks/useLocales';
import { getDescriptionFromURL } from '../../redux/slices/demoDes';
import { useDispatch } from '../../redux/store';

// стили для формы отправки url
const useStyles = makeStyles((theme) => ({
  wrapperAll: {
    width: '100%',
    marginTop: '12px'
  },
  textarea: {
    marginRight: '24px'
  },
  wrapperUrl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    display: 'block'
  }
}));

export default function PasteUrlDemoPage() {
  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [isUrlValid, setIsUrlValid] = React.useState(false);
  const validateText = translate('general.validate');
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.demoDes);

  useEffect(() => {
    const url = value;
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    if (reg.test(url)) {
      setIsUrlValid(true);
    } else {
      setIsUrlValid(false);
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const buttonClick = () => {
    if (isUrlValid) {
      dispatch(getDescriptionFromURL(value, lang));
    }
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      buttonClick();
    }
  };

  return (
    <div className={classes.wrapperAll}>
      <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.wrapperUrl}>
          <TextField
            id="outlined-multiline-flexible"
            label={translate('general.input')}
            maxRows={1}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            variant="outlined"
            size="small"
            sx={{ mr: 3 }}
          />
          <Button
            sx={{ pt: '8px', pb: '8px' }}
            variant="contained"
            size="medium"
            color={isUrlValid ? 'primary' : 'inherit'}
            className={classes.button}
            onClick={buttonClick}
            disabled={!isUrlValid}
          >
            {translate('general.click')}
          </Button>
        </div>
      </form>
    </div>
  );
}
