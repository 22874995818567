/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import LinkM from '@material-ui/core/Link';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Slider from 'react-slick';
import { Typography } from '@material-ui/core';
import card1 from '../../customImages/card1.png';
import card2 from '../../customImages/card2.png';
import card3 from '../../customImages/card3.png';
import card4 from '../../customImages/card4.png';
import useLocales from '../../hooks/useLocales';

export default function WhatWeCanSlider() {
  const { translate } = useLocales();
  const [cardIndex, setCardIndex] = useState(0);

  const imagesData = [
    {
      sources: card1
    },
    {
      sources: card2
    },
    {
      sources: card3
    },
    {
      sources: card4
    }
  ];

  const NextArrow = ({ onClick }) => (
    <Box component="div" className="arrow next" onClick={onClick}>
      <ArrowForwardIosRoundedIcon height="14px" />
    </Box>
  );

  const PrevArrow = ({ onClick }) => (
    <Box component="div" className="arrow prev" onClick={onClick}>
      <ArrowBackIosRoundedIcon height="14px" />
    </Box>
  );

  const settings = {
    infinite: true,
    lazyLoad: true,
    centerPadding: 0,
    slidesToShow: 4,
    speed: 300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCardIndex(next),
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Box component="div" sx={{ maxWidth: '960px', m: '0 auto' }}>
      <Slider {...settings}>
        {imagesData.map((e, idx) => (
          <Box key={idx} component="div" className={idx === cardIndex ? 'slide activeSlide' : 'slide'}>
            <Card sx={{ p: 1, height: 320, width: 200, borderRadius: '8px', margin: '20px auto' }}>
              <CardMedia component="img" height="156" image={e.sources} />
              <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                {idx === 0 && (
                  <LinkM underline="hover" href="https://app.picpack.io/demo_ru">
                    <Typography variant="subtitle2">{translate('page_demo.page_card_1')}</Typography>
                  </LinkM>
                )}
                {idx === 1 && <Typography variant="subtitle2">{translate('page_demo.page_card_2')}</Typography>}
                {idx === 2 && <Typography variant="subtitle2">{translate('page_demo.page_card_3')}</Typography>}
                {idx === 3 && (
                  <Typography variant="subtitle2">
                    {translate('page_demo.page_card_4')}
                    <LinkM target="_blank" underline="hover" href="https://t.me/picpack_bot">
                      Telegram bot
                    </LinkM>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
