import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import errorsHandler from '../../utils/errorsHandler';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: { isError: false, errorMessage: '' },
  notification: { isNotification: false, notificationType: 'info', notificationMessage: 'someinfo' },
  isAuth: false,
  name: null,
  surname: null,
  email: null,
  lang: 'ru', // hardcode
  isOpenModal: false,
  isPasswordChanged: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    openModalAuth(state) {
      state.isOpenModal = true;
      state.notification.isNotification = false;
    },
    closeModalAuth(state) {
      state.isOpenModal = false;
      state.notification.isNotification = false;
    },
    getUserLogout(state) {
      state.isAuth = false;
    },
    logInUser(state) {
      state.isAuth = true;
    },
    setNotification(state) {
      state.notification.isNotification = true;
      state.notification.notificationType = 'info';
      state.notification.notificationMessage = 'Для скачивания HD изображений, пожалуйста зарегистрируйтесь';
    },
    setErrorNotification(state, payload) {
      state.notification.isNotification = true;
      state.notification.notificationType = 'error';
      state.notification.notificationMessage = payload.payload;
    },
    clearNotification(state) {
      state.notification.isNotification = false;
    },
    setUserInfo(state, payload) {
      state.name = payload?.payload?.name || null;
      state.surname = payload?.payload?.surname || null;
      state.email = payload?.payload?.email || null;
    },
    clearUserInfo(state) {
      state.name = null;
      state.surname = null;
      state.email = null;
    },
    setPasswordChanged(state) {
      state.isPasswordChanged = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModalAuth, closeModalAuth, setNotification, setUserInfo, clearNotification } = slice.actions;

// ----------------------------------------------------------------------

export function registrationAction({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userData = {
        email: email.toLowerCase(),
        password,
        first_name: firstName,
        last_name: lastName,
        country: 'RU', // hardcode
        language: 'ru' // hardcode
      };
      const { data } = await axios.post('/api/auth/register', userData);
      if (!data?.access_token || !data?.refresh_token) throw new Error();
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      dispatch(getDataUserAction());
      dispatch(slice.actions.closeModalAuth());
      dispatch(slice.actions.logInUser());
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    dispatch(slice.actions.endLoading());
  };
}

export function loginAction({ email, password, remember }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const userData = {
        email: email.toLowerCase(),
        password
      };
      const response = await axios.post('/api/auth/login', userData);
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      dispatch(getDataUserAction(!remember));
      dispatch(slice.actions.closeModalAuth());
      dispatch(slice.actions.logInUser());
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    dispatch(slice.actions.endLoading());
  };
}

export function loginWithVKAction(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/auth/vk', data);
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      dispatch(getDataUserAction());
      dispatch(slice.actions.closeModalAuth());
      dispatch(slice.actions.logInUser());
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    dispatch(slice.actions.endLoading());
  };
}

export function loginWithGoogleAction(token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        token_id: token
      };
      const response = await axios.post('/api/auth/google', data);
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      dispatch(getDataUserAction());
      dispatch(slice.actions.closeModalAuth());
      dispatch(slice.actions.logInUser());
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    dispatch(slice.actions.endLoading());
  };
}

export function loginWithYandexAction(token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        access_token: token
      };
      const response = await axios.post('/api/auth/yandex', data);
      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      dispatch(getDataUserAction());
      dispatch(slice.actions.closeModalAuth());
      dispatch(slice.actions.logInUser());
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    dispatch(slice.actions.endLoading());
  };
}

export function getDataUserAction(deleteToken) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/user-account/');
      dispatch(
        setUserInfo({
          name: response.data.data.first_name,
          surname: response.data.data.last_name,
          email: response.data.data.email
        })
      );
      dispatch(slice.actions.logInUser());
      if (deleteToken) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
  };
}

export function logOutAction() {
  return async (dispatch) => {
    try {
      // const refresh = localStorage.getItem('refresh_token');
      // await axios.post(
      //   '/api/auth/logout',
      //   {},
      //   {
      //     headers: {
      //       Authorization: `Bearer ${refresh}`
      //     }
      //   }
      // );
      dispatch(slice.actions.getUserLogout());
      dispatch(slice.actions.clearUserInfo());
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    } catch (error) {
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
  };
}

export function sendResetPasswordAction(password) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/auth/reset-password', { password });
      dispatch(slice.actions.setPasswordChanged());
    } catch (error) {
      // dispatch(slice.actions.setErrorNotification('Пароль должен содержать латинскую букву(ы)'));
      dispatch(slice.actions.setErrorNotification(errorsHandler(error)));
    }
    localStorage.removeItem('access_token');
    dispatch(slice.actions.endLoading());
  };
}
