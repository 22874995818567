export const errorsConfig = [
  {
    code: 500,
    error: 'EMAIL_NOT_PROVIDED',
    message: 'No email provided'
  },
  {
    code: 500,
    error: 'INVALID_EMAIL',
    message: 'Invalid email format'
  },
  {
    code: 500,
    error: 'INVALID_COUNTRY',
    message: 'Invalid country format'
  },
  {
    code: 500,
    error: 'INVALID_LANGUAGE',
    message: 'Invalid language format'
  },
  {
    code: 500,
    error: 'UNSUPPORTED_LANG',
    message: 'Unsupported language!'
  },
  {
    code: 500,
    error: 'PASSWORD_NOT_PROVIDED',
    message: 'No password provided'
  },
  {
    code: 500,
    error: 'FIRST_NAME_NOT_PROVIDED',
    message: 'No first name provided'
  },
  {
    code: 500,
    error: 'DATABASE_PROBLEM',
    message: 'Error writing data to DB'
  },
  {
    code: 500,
    error: 'USER_EXISTS',
    message: 'User is already in DB'
  },
  {
    code: 500,
    error: 'PASSWORD_WEAK',
    message: 'Password must contains letter and have at least 4 symbols'
  },
  {
    code: 500,
    error: 'WRONG_PASS',
    message: 'Password is wrong'
  },
  {
    code: 500,
    error: 'USER_NOT_EXISTS',
    message: 'No this user in DB'
  },
  {
    code: 500,
    error: 'TOKEN_EXP',
    message: 'Token has expired!'
  },
  {
    code: 500,
    error: 'TOKEN_REVOKED',
    message: 'Token has been revoked'
  },
  {
    code: 500,
    error: 'INVALID_TOKEN_PAYLOAD',
    message: 'Invalid token payload'
  },
  {
    code: 500,
    error: 'INVALID_TOKEN_SIGNATURE',
    message: 'Invalid token signature'
  },
  {
    code: 500,
    error: 'REFRESH_TOKEN_NEEDED',
    message: 'Only refresh token is allowed'
  },
  {
    code: 500,
    error: 'NOT_ENOUGH_TOKEN_SEGMENTS',
    message: 'Not enough segments'
  },
  {
    code: 500,
    error: 'BAD_AUTH_TOKEN_HEADER',
    message: 'Bad Authorization header'
  },
  {
    code: 500,
    error: 'TOKEN_NOT_PROVIDED',
    message: 'Missing JWT in headers or query_string (or wrong keys)'
  },
  {
    code: 500,
    error: 'BAD_TOKEN',
    message: 'Another problem with token'
  },
  {
    code: 500,
    error: 'TOKEN_NOT_IN_DB',
    message: 'Token is not in DB. It was revoked!'
  },
  {
    code: 500,
    error: 'NEW_PASSWORD_NOT_PROVIDED',
    message: 'No new password provided'
  },
  {
    code: 500,
    error: 'WRONG_TYPE_TOKEN',
    message: 'Token type is wrong'
  },
  {
    code: 500,
    error: 'INVALID_TOKEN',
    message: 'Bad token jti'
  },
  {
    code: 500,
    error: 'INVALID_TOKEN_HEADER',
    message: 'Invalid token header'
  },
  {
    code: 500,
    error: 'ACCESS_TOKEN_NEEDED',
    message: 'Only access token is allowed'
  },
  {
    code: 500,
    error: 'COUNTRY_NOT_EXIST',
    message: 'No this country in list'
  }
];
