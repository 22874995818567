import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/core/Alert';
import { alertErrorClose, alertSuccessClose, sendEmailContactBgRemove } from '../../redux/slices/demoBGR';
import useLocales from '../../hooks/useLocales';

export default function Subscribe() {
  const [lang] = useState(localStorage.getItem('lang'));
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const mobile = useMediaQuery('@media (min-width: 400px)');
  const [text, setText] = useState('');
  const { successForm, errorForm } = useSelector((state) => state.demoBGR);
  const [checkDisplayButton, setCheckDisplayButton] = useState(true);

  // убирается пропадание текста при повороте ID43
  useEffect(() => {
    const newText = localStorage.getItem('text');
    setText(newText);
  }, []);

  const textFieldHandel = (e) => {
    const textL = e.target.value;
    setText(textL);
    const reg = /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(textL)) {
      setCheckDisplayButton(false);
    } else {
      setCheckDisplayButton(true);
    }
  };

  const buttonClickHandel = () => {
    dispatch(sendEmailContactBgRemove(text, lang));
    setText('');
    setCheckDisplayButton(true);
  };

  const closeSuccessAlertHandel = () => {
    dispatch(alertErrorClose());
  };

  const closeErrorAlertHandel = () => {
    dispatch(alertSuccessClose());
  };

  return (
    <Box component="div" sx={{ m: '0 auto', maxWidth: '580px', pb: '85px', pl: '30px', pr: '30px' }}>
      <Typography variant="h4" sx={{ mb: '12px' }}>
        {translate('bgRemove.subscribe.title')}
      </Typography>
      <Typography variant="body2" sx={{ mb: '40px', color: '#637381' }}>
        {translate('bgRemove.subscribe.sub_title')}
      </Typography>

      <Box
        component="div"
        sx={
          mobile ? { display: 'flex', justifyContent: 'space-between' } : { display: 'flex', flexDirection: 'column' }
        }
      >
        <TextField
          size="small"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={text}
          onChange={textFieldHandel}
          sx={{ width: '100%', maxWidth: '360px' }}
        />
        <Button
          variant="contained"
          color="grey"
          onClick={buttonClickHandel}
          disabled={checkDisplayButton}
          sx={
            mobile
              ? { p: '8px 29px', ml: '24px', backgroundColor: '#DFE3E8' }
              : { p: '8px 29px', mt: '12px', backgroundColor: '#DFE3E8' }
          }
        >
          {translate('general.send')}
        </Button>
      </Box>
      {successForm && (
        <Alert sx={{ mt: '20px', cursor: 'pointer' }} onClick={closeErrorAlertHandel}>
          {translate('bgRemove.subscribe.thanks')}
        </Alert>
      )}
      {errorForm && (
        <Alert sx={{ mt: '20px', cursor: 'pointer' }} severity="info" onClick={closeSuccessAlertHandel}>
          {translate('bgRemove.subscribe.error_form')}
        </Alert>
      )}
    </Box>
  );
}
