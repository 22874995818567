import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, Container, Typography } from '@material-ui/core';

// components
import Page from '../../components/Page';
import ForgotPasswordForm from '../../components/authentication/forgot-password/ForgotPasswordForm';

//
import { SentIcon } from '../../assets';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const { translate } = useLocales();

  return (
    <RootStyle>
      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                {translate('forgotPassword.heading')}
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>{translate('forgotPassword.description')}</Typography>

              <ForgotPasswordForm setSent={setSent} />

              <Button fullWidth size="large" component={RouterLink} to="/" sx={{ mt: 1 }}>
                {translate('forgotPassword.button_back_title')}
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

              <Typography variant="h3" gutterBottom>
                {translate('forgotPassword.request.title')}
              </Typography>
              <Typography>
                {translate('forgotPassword.request.body')} &nbsp;
                <strong>{email}</strong>
                <br />
                {translate('forgotPassword.request.reminder')}
              </Typography>

              <Button size="large" variant="contained" component={RouterLink} to="/" sx={{ mt: 5 }}>
                {translate('forgotPassword.button_back_title')}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
