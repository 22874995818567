import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
// components
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@material-ui/core/Drawer';
import Logo from '../components/Logo';
import useLocales from '../hooks/useLocales';
import { openModalAuth } from '../redux/slices/user';
import AccountPopover from './AccountPopover';
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  padding: theme.spacing(3, 3, 3),
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: '24px 0',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)'
  },
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#F4F6F8'
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentLang, translate } = useLocales();
  const { isAuth } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const openHandler = () => {
    setOpen(!open);
  };
  const openModal = () => {
    dispatch(openModalAuth());
  };

  const handleChangeLang = () => {
    const path = window.location.pathname.split('/').pop();
    if (path === 'image_desc') {
      navigate(`/${currentLang.value}/bgRemove`);
    } else {
      navigate(`/${currentLang.value}/image_desc`);
    }
  };

  return (
    <>
      <HeaderStyle>
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: '20px' }}>
            <Logo />
          </Box>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {sm && (
              <Button variant="contained" sx={{ mr: 2 }} onClick={() => handleChangeLang()}>
                {translate('general.des_page')}
              </Button>
            )}
            {sm && (
              <RouterLink to={`/${currentLang.value}/FAQ`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" sx={{ mr: 2 }}>
                  {translate('general.FAQ')}
                </Button>
              </RouterLink>
            )}
            {sm && !isAuth && (
              <Button variant="outlined" sx={{ mr: 2 }} onClick={openModal}>
                {translate('general.signIn')}/{translate('general.registration')}
              </Button>
            )}
            {sm && isAuth && <AccountPopover />}
            {!sm && (
              <Box component="div">
                <Button onClick={openHandler}>
                  <MenuIcon />
                </Button>
                <Drawer anchor="top" open={open} onClose={openHandler}>
                  <Box component="div" sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" sx={{ mr: 2 }} onClick={() => handleChangeLang()}>
                      {translate('general.des_page')}
                    </Button>
                    <RouterLink to={`/${currentLang.value}/FAQ`} style={{ textDecoration: 'none' }}>
                      <Button variant="contained" sx={{ mr: 2 }}>
                        {translate('general.FAQ')}
                      </Button>
                    </RouterLink>
                    {!isAuth ? (
                      <Button variant="outlined" sx={{ mr: 2 }} onClick={openModal}>
                        {translate('general.signIn')}/{translate('general.registration')}
                      </Button>
                    ) : (
                      <AccountPopover />
                    )}
                  </Box>
                </Drawer>
              </Box>
            )}
          </Box>
        </Container>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
