import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../redux/store';
import { deleteConfirmToken, hasError, setSuccessPay, setFailPay } from '../../redux/slices/pay';
import { getDataUserAction } from '../../redux/slices/user';

export default function YooKassawidget() {
  const dispatch = useDispatch();

  const { confirmToken } = useSelector((state) => state.pay);

  useEffect(
    () => () => {
      if (checkout?.destroy) checkout.destroy();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkout = confirmToken
    ? new window.YooMoneyCheckoutWidget({
        confirmation_token: confirmToken,
        customization: {
          modal: true
          // payment_methods: ['bank_card']
        },
        error_callback: (error) => {
          dispatch(hasError(error));
        }
      })
    : null;

  if (checkout) {
    checkout?.on('modal_close', () => {
      dispatch(deleteConfirmToken());
      //   if (checkout?.destroy) checkout.destroy();
    });

    checkout?.on('complete', async () => {
      dispatch(setSuccessPay());
      dispatch(deleteConfirmToken());
      await dispatch(getDataUserAction());
      if (checkout?.destroy) checkout.destroy();
    });

    checkout?.on('fail', () => {
      console.log('Оплата не прошла');
      dispatch(setFailPay());
      dispatch(deleteConfirmToken());
      //   if (checkout?.destroy) checkout.destroy();
    });

    checkout?.render();
  }

  return null;
}
