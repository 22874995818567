/* eslint-disable react-hooks/exhaustive-deps */
import { isString } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
// utils
import Button from '@material-ui/core/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
//
import useLocales from '../../hooks/useLocales';
import { allowedFileFormats } from '../../configs';
import { useDispatch, useSelector } from '../../redux/store';
import { clearStore, hasError, openModalUrl, removeBgFromFileImg } from '../../redux/slices/demoBGR';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: `none`,
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  /* padding: theme.spacing(5, 0), */
  height: '300px',
  borderRadius: '20px',
  backgroundImage: `url(
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23065176FF' stroke-width='4' stroke-dasharray='17%2c 17' stroke-dashoffset='42' stroke-linecap='square'/%3e%3c/svg%3e"
  )`,
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadSingleFileBGR.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sx: PropTypes.object
};

export default function UploadSingleFileBGR({ error, sx, ...other }) {
  const [file, setFile] = useState(null);
  const { sources, sourcesUrl } = useSelector((state) => state.demoBGR);
  const [rejected, setRejected] = useState(false);

  const { translate } = useLocales();
  const [lang] = React.useState(localStorage.getItem('lang'));

  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    const acceptedFile = acceptedFiles[0];
    if (acceptedFile) {
      dispatch(clearStore());
      setFile({
        ...acceptedFile,
        preview: URL.createObjectURL(acceptedFile)
      });
      dispatch(removeBgFromFileImg(acceptedFile, lang));
    }
    // getDescriptionFromFileImg
  }, []);

  const bytesPerMegabyte = 1048576;
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept: allowedFileFormats.map((format) => `.${format}`).join(','),
    maxSize: bytesPerMegabyte * 15,
    onDrop,
    ...other
  });

  // очищает список некорректных файлов
  useEffect(() => {
    if (rejected) {
      fileRejections.splice(0, fileRejections.length);
      setRejected(false);
    }
    if (fileRejections[0]) {
      if (fileRejections[0].errors[0]) {
        dispatch(hasError(fileRejections[0].errors[0]));
      }
    }
  }, [rejected, fileRejections]);

  // @todo
  // определет текст ошибки
  // function getErrorMessage(error) {
  //   const { code } = error;
  //   if (code === 'file-invalid-type') {
  //     return translate('errors.unsupported_file_format_message', { lng: lang });
  //   }
  //   if (code === 'file-too-large') {
  //     return translate('errors.too_large_file_message', { lng: lang });
  //   }
  //   return translate('errors.unknown_error', { lng: lang });
  // }

  const urlHandler = (e) => {
    e.stopPropagation();
    dispatch(openModalUrl());
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main'
          }),
          ...(file && { padding: '12% 0' })
        }}
      >
        <input {...getInputProps()} />
        {!isDragReject && (
          <Box sx={{ p: 3 }}>
            <Button size="large" variant="contained" startIcon={<AddPhotoAlternateIcon />}>
              {translate('bgRemove.starter_component.upload.upload_text')}
            </Button>
            <Box component="div" sx={{ position: 'absolute', pt: '12px' }}>
              <Typography variant="body2" sx={{ color: '#065176', fontSize: '12px' }}>
                {translate('bgRemove.starter_component.upload.sub_upload_text_part1')}
              </Typography>
              <Typography variant="body2" sx={{ color: '#065176', fontSize: '12px' }}>
                {translate('bgRemove.starter_component.upload.sub_upload_text_part2')}{' '}
                <Box
                  onClick={urlHandler}
                  component="span"
                  sx={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  URL
                </Box>
                <Box
                  component="span"
                  sx={{
                    border: '1px solid rgba(6,8,118,0.18)',
                    borderRadius: '5px',
                    fontSize: '10px',
                    p: '5px',
                    ml: '10px'
                  }}
                >
                  CTRL
                </Box>
                <Box component="span" sx={{ ml: '3px', mr: '3px' }}>
                  +
                </Box>
                <Box
                  component="span"
                  sx={{ border: '1px solid rgba(6,8,118,0.18)', borderRadius: '5px', fontSize: '10px', p: '5px' }}
                >
                  V
                </Box>
              </Typography>
            </Box>
          </Box>
        )}
        {sourcesUrl && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)',
              bgcolor: '#F4F6F8'
            }}
          />
        )}
        {file && sourcesUrl && sources.length < 1 && (
          <Box
            component="img"
            alt="file preview"
            src={isString(file) ? file : file.preview}
            sx={{
              top: 8,
              objectFit: 'contain',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)'
            }}
          />
        )}
        {sources.length > 1 && sourcesUrl && (
          <Box
            component="img"
            alt="file preview"
            src={sources}
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: 'contain',
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)'
            }}
          />
        )}
        {/* {isLoading && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0,0,0,0.5)',
              zIndex: '10'
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </DropZoneStyle>

      {/* {fileRejections.length > 0 && ( */}
      {/*  <ModalUniversal */}
      {/*    title={translate('general.error')} */}
      {/*    errorText={getErrorMessage(fileRejections[0].errors[0])} */}
      {/*    isOpen */}
      {/*    onCloseModal={() => setRejected(true)} */}
      {/*  /> */}
      {/* )} */}
    </Box>
  );
}
