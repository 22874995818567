/* eslint-disable react/prop-types */
import Slider from 'react-slick';
import Box from '@material-ui/core/Box';
import React, { useState } from 'react';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import canadaGoose from '../../customImages/canada-goose-6578211_1920.jpg';
import hartmans from '../../customImages/hartmanns-4823240_1920.jpg';
import kiteSurfing from '../../customImages/kite-surfing-1960616_1920.jpg';
import motorCycle from '../../customImages/motorcycle-77552_1920.jpg';
import snow from '../../customImages/snow-3110761_1920.jpg';
import child from '../../customImages/child-613199_1280.jpg';
import { getDescriptionFromURL } from '../../redux/slices/demoDes';
import { useDispatch } from '../../redux/store';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const imagesData = [
  {
    sources: canadaGoose
  },
  {
    sources: hartmans
  },
  {
    sources: kiteSurfing
  },
  {
    sources: motorCycle
  },
  {
    sources: snow
  },
  {
    sources: child
  }
];

export default function SliderMobile() {
  const NextArrow = ({ onClick }) => (
    <Box component="div" className="arrowDes nextDes" onClick={onClick}>
      <ArrowForwardIosRoundedIcon height="14px" />
    </Box>
  );

  const PrevArrow = ({ onClick }) => (
    <Box component="div" className="arrowDes prevDes" onClick={onClick}>
      <ArrowBackIosRoundedIcon height="14px" />
    </Box>
  );
  const [lang] = React.useState(localStorage.getItem('lang'));

  const [imageIndex, setImageIndex] = useState(0);
  const dispatch = useDispatch();

  const clickOnImg = (sources) => {
    const host = window.location.origin;
    if (sources[0] === '.') {
      sources = sources.substr(1);
    }
    const imgSrc = host + sources;
    dispatch(getDescriptionFromURL(imgSrc, lang));
  };

  const settings = {
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    centerPadding: 0,
    slidesToShow: 3,
    speed: 300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next)
  };
  return (
    <Box component="div">
      <Slider {...settings}>
        {imagesData.map((e, idx) => (
          <Box
            key={idx}
            component="div"
            className={idx === imageIndex ? 'slideDes activeSlideDes' : 'slideDes'}
            onClick={() => clickOnImg(e.sources)}
          >
            <Box component="img" src={`${e.sources}`} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
