import { useState } from 'react';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';
// components
import * as dayjs from 'dayjs';
import { userFormatDate } from '../../utils/formatDate';
import Page from '../Page';
import PricingCard from './PricingCard';
import ModalUniversal from '../Modal/ModalUniversal';
import YooKassawidget from '../YooKassawidget';
import { startLoading, endLoading, hasError, getConformationToken } from '../../redux/slices/pay';
import { openModalAuth } from '../../redux/slices/user';
import { useDispatch, useSelector } from '../../redux/store';
//
import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../../assets';
import useLocales from '../../hooks/useLocales';
import tariffPlans from '../../utils/mock-data/tariffPlans'; // mock data
import getPriceOfTariffPlan from '../../utils/getPriceOfTariffPlan';
import smoothScroll from '../../utils/smoothScroll';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Pricing() {
  const { translate } = useLocales();

  const dispatch = useDispatch();
  const { price } = useSelector((state) => state.pay);
  const { isAuth } = useSelector((state) => state.user);

  const [isOpen, setOpen] = useState(false);

  const clickPlanHandler = async (e) => {
    if (!isAuth) {
      console.log('no auth');
      smoothScroll().finally(() => dispatch(openModalAuth()));
      return;
    }
    dispatch(startLoading());
    const selectedPlan = e.target.getAttribute('data-plan');

    try {
      if (selectedPlan === 'custom') {
        dispatch(getConformationToken(price));
      } else {
        const data = getPriceOfTariffPlan(selectedPlan);
        if (data) {
          dispatch(getConformationToken(data));
        } else {
          // здесь нужно обработать ошибку
        }
        dispatch(endLoading());
      }
    } catch (error) {
      dispatch(hasError(error));
      dispatch(endLoading());
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'STARTER':
        return <PlanStarterIcon />;
      case 'PREMIUM':
        return <PlanPremiumIcon />;
      case 'CUSTOM':
        return <PlanFreeIcon />;
      default:
        return null;
    }
  };

  const plansList = tariffPlans.map((plan) => ({
    id: plan?.id || null,
    subscription: plan?.subscription ? translate(plan.subscription) : null,
    icon: getIcon(plan?.icon),
    lists: plan?.lists
      ? plan.lists.map((planListsItem) => ({
          text: translate(planListsItem?.text) || null,
          isAvailable: planListsItem?.isAvailable || null
        }))
      : null,
    labelAction: plan?.labelAction ? translate(plan.labelAction) : null,
    isSpecial: plan?.isSpecial,
    discount: plan?.discount || false,
    price: plan?.price ? `${plan.price} ${translate('pricing.cards.prices.coins')}` : null,
    discountPrice: plan?.discount ? `${Math.ceil(Number(plan.price) / 2)} ${translate()}` : null,
    customPlan: plan?.customPlan || null,
    popular: plan?.popular ? translate(plan.popular) : null
  }));

  const dateEndOfAction = dayjs().add(1, 'month').format(userFormatDate); // HARDCODE

  return (
    <RootStyle title="Pricing | Minimal-UI">
      <Container maxWidth="lg">
        <Typography variant="h3" align="center" paragraph>
          {translate('pricing.head.first')}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          paragraph
          sx={{
            backgroundColor: '#00ab55',
            width: '30%',
            textAlign: 'center',
            margin: '10px auto',
            padding: '5px 0',
            borderRadius: 1,
            color: 'white'
          }}
        >
          {translate('pricing.head.second')} {dateEndOfAction}
        </Typography>
        <Typography align="center" sx={{ color: 'text.secondary' }}>
          {translate('pricing.subhead')}
        </Typography>
        <br />
        <Grid container spacing={3} alignItems="stretch">
          {plansList.map((tariffPlan, idx) => (
            <Grid item lg={4} xs={12} md={6} key={tariffPlan.id + idx}>
              <PricingCard plan={tariffPlan} choosePlanHandler={() => clickPlanHandler} />
            </Grid>
          ))}
          <Grid item lg={4} xs={12} md={6}>
            {/* <PricingPlanCardCustom card={CUSTOM_PLAN} /> */}
          </Grid>
          {/* {PLANS.map((card, index) => (
            <Grid item lg={4} xs={12} md={6} key={card.subscription}>
              <PricingPlanCard checked={checked} isSpecial={card.isSpecial} card={card} index={index} />
            </Grid>
          ))} */}
        </Grid>
        <ModalUniversal isOpen={isOpen} onCloseModal={() => setOpen(false)}>
          <div id="payment-form" />
          <YooKassawidget />
        </ModalUniversal>
      </Container>
    </RootStyle>
  );
}
