import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import { Container, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import LinkM from '@material-ui/core/Link';
import { Link } from 'react-scroll';
import card1 from '../../customImages/card1.png';
import card2 from '../../customImages/card2.png';
import card3 from '../../customImages/card3.png';
import card4 from '../../customImages/card4.png';
import useLocales from '../../hooks/useLocales';
import WhatWeCanSlider from './WhatWeCanSlider';

export default function WhatWeCan() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.up('sm'));
  const miniMobile = useMediaQuery('@media (min-width:400px)');
  const { translate } = useLocales();

  if (mobile && matches)
    return (
      <Box component="div" sx={{ pt: 6, pb: 4 }}>
        <Container maxWidth="lg">
          <Typography variant="h4" sx={{ mb: 3 }}>
            {translate('page_demo.card_title')}
          </Typography>
          <Typography variant="h6" sx={{ mb: '20px' }}>
            {translate('page_demo.order.title')}
            <Button href="mailto:info@picpack.io" variant="outlined" sx={{ ml: 2 }}>
              {translate('page_demo.order.button')}
            </Button>
          </Typography>
          <Grid container spacing={3} sx={{ mb: 7 }}>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card1} alt="card1" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <LinkM underline="hover" href="https://app.picpack.io/demo_ru">
                    <Typography variant="subtitle2">{translate('page_demo.page_card_1')}</Typography>
                  </LinkM>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card2} alt="card2" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">{translate('page_demo.page_card_2')}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card3} alt="card3" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">{translate('page_demo.page_card_3')}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardMedia component="img" height="156" image={card4} alt="card4" />
                <CardContent sx={{ p: '0px 8px', mt: 2 }}>
                  <Typography variant="subtitle2">
                    {translate('page_demo.page_card_4')}
                    <LinkM target="_blank" underline="hover" href="https://t.me/picpack_bot">
                      Telegram bot
                    </LinkM>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md>
              <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
                <CardContent display="flex">
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {translate('page_demo.page_card_5_title')}
                  </Typography>
                  <Typography variant="caption" sx={{ fontSize: '14px', lineHeight: '22px', color: '#637381' }}>
                    {translate('page_demo.page_card_5_main')}
                  </Typography>
                  <Link to="earlyAccess" smooth offset={50} duration={350}>
                    <Box sx={{ mt: 3 }}>
                      <Button variant="outlined">{translate('page_demo.page_card_5_button')}</Button>
                    </Box>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );

  return (
    <Box component="div" sx={{ m: '0 auto', maxWidth: '1200px' }}>
      <Container sx={{ pt: '60px', pb: '60px', pl: '15px', pr: '15px' }}>
        <Typography align="center" variant="h4" sx={{ mb: '20px' }}>
          {translate('page_demo.card_title')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography lineHeight="2" align="center" variant="h6" sx={{ mb: '20px', fontWeight: '30px' }}>
            {translate('page_demo.order.title')}
          </Typography>
          <Button href="mailto:info@picpack.io" variant="outlined" sx={{ width: '100px', mb: '20px' }}>
            {translate('page_demo.order.button')}
          </Button>
        </Box>
        <WhatWeCanSlider />
        <Grid container spacing={1}>
          <Grid item md>
            <Card sx={{ p: 1, height: 320, borderRadius: '8px' }}>
              <CardContent display="flex">
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  {translate('page_demo.page_card_5_title')}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '14px', lineHeight: '22px', color: '#637381' }}>
                  {translate('page_demo.page_card_5_main')}
                </Typography>
                <Link to="earlyAccess" smooth offset={50} duration={350}>
                  <Box sx={{ mt: 3 }}>
                    <Button variant="outlined">{translate('page_demo.page_card_5_button')}</Button>
                  </Box>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
