import { motion } from 'framer-motion';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { TextAnimate, varWrapEnter, varFadeInRight } from '../../components/animate';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundImage: 'url(/static/overlay.svg), url(/static/faqs/hero.jpg)',
  padding: theme.spacing(1, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0
  }
}));

// ----------------------------------------------------------------------

export default function FaqsHero() {
  const medium = useMediaQuery('@media (min-width:840px)');
  const { translate } = useLocales();
  const navigate = useNavigate();

  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Box component="div" sx={{ width: '100%', display: 'flex', justifyContent: 'end', pt: 2, pr: 2 }}>
        <Button onClick={() => navigate(-1)} variant="contained" sx={{ mr: 2 }}>
          {translate('general.back')}
        </Button>
      </Box>
      <Container>
        <TextAnimate text="FAQ" sx={{ color: 'primary.main', mt: medium ? 20 : 10 }} variants={varFadeInRight} />
        <br />
        <Box sx={{ display: 'inline-flex' }}>
          <TextAnimate text="Часто" sx={{ mr: medium ? 2 : 1, typography: medium ? 'h1' : 'h4' }} />
          <TextAnimate text="задаваемые" sx={{ mr: medium ? 2 : 1, typography: medium ? 'h1' : 'h4' }} />
          <TextAnimate text="вопросы" sx={{ mr: medium ? 2 : 1, typography: medium ? 'h1' : 'h4' }} />
        </Box>
        <Fade in timeout={2000}>
          <Box sx={{ pb: 15 }}>
            <Typography variant={medium ? 'h2' : 'h4'} sx={{ mt: 5 }}>
              Политика конфиденциальности
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
              Настоящий документ «Политика конфиденциальности» (далее – по тексту – «Политика») представляет собой
              правила использования сайтом - picpack.io, ООО "МАЙНДСЭТ" (далее – Оператор) персональной информации
              Пользователя, которую Оператор, включая всех лиц, входящих в одну группу с Оператором, могут получить о
              Пользователе во время использования им любого из сайтов, сервисов, служб, программ, продуктов или услуг
              Оператора (далее – Сайт) и в ходе исполнения Оператором любых соглашений и договоров с Пользователем.
              Согласие Пользователя с Политикой, выраженное им в рамках отношений с одним из перечисленных лиц,
              распространяется на все остальные перечисленные лица. Использование Сайта означает безоговорочное согласие
              Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в
              случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта.
            </Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h6">1. Общие положения политики</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1.1. Настоящая Политика является неотъемлемой частью Публичной оферты (далее – «Оферта»), размещенной
                  и/или доступной в сети Интернет по адресу: picpack.io, а также иных заключаемых с Пользователем
                  договоров, когда это прямо предусмотрено их условиями.
                </Typography>
                <Typography>
                  1.2. Настоящая Политика составлена в соответствии с Федеральным законом «О персональных данных» №
                  152-ФЗ от 27 июля 2006 г., а также иными нормативно-правовыми актами Российской Федерации в области
                  защиты и обработки персональных данных и действует в отношении всех персональных данных, которые
                  Оператор может получить от Пользователя, являющегося стороной по гражданско-правовому договору.
                </Typography>
                <Typography>
                  1.3. Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в заголовке
                  Политики указывается дата последнего обновления редакции. Новая редакция Политики вступает в силу с
                  момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики.
                </Typography>
                <Typography>
                  1.4. К настоящей Политике, включая толкование ее положений и порядок принятия, исполнения, изменения и
                  прекращения, подлежит применению законодательство Российской Федерации.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography variant="h6">
                  2. Персональная информация Пользователей, которую обрабатывает Сайт
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>2.1. Под персональной информацией в настоящей Политике понимается:</Typography>
                <Typography>
                  2.1.1. Информация, предоставляемая Пользователем самостоятельно при регистрации (создании учётной
                  записи) или в процессе использования Сайта, включая персональные данные Пользователя. Обязательная для
                  предоставления Сайтом информация помечена специальным образом. Иная информация предоставляется
                  Пользователем на его усмотрение.
                </Typography>
                <Typography>
                  2.1.2. Данные, которые передаются в автоматическом режиме Сайту в процессе их использования с помощью
                  установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные
                  файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой
                  осуществляется доступ к Сайту), технические характеристики оборудования и программного обеспечения,
                  используемых Пользователем, дата и время доступа к Сайту, адреса запрашиваемых страниц и иная подобная
                  информация.
                </Typography>
                <Typography>
                  2.1.3. Иная информация о Пользователе, обработка которой предусмотрена условиями использования Сайта.
                </Typography>
                <Typography>
                  2.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сайта. Сайт
                  не контролирует и не несет ответственность за обработку информации сайтами третьих лиц, на которые
                  Пользователь может перейти по ссылкам, доступным на Сайте.
                </Typography>
                <Typography>
                  2.3. Сайт не проверяет достоверность персональной информации, предоставляемой Пользователем, и не
                  имеет возможности оценивать его дееспособность.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">3. Цели обработки персональной информации Пользователей </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  3.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления
                  сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда
                  законодательством предусмотрено обязательное хранение персональной информации в течение определенного
                  законом срока.
                </Typography>
                <Typography>3.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</Typography>
                <Typography>
                  3.2.1. Идентификация стороны в рамках сервисов, соглашений и договоров с Сайтом.
                </Typography>
                <Typography>
                  3.2.2. Предоставление Пользователю персонализированных сервисов и услуг, а также исполнение соглашений
                  и договоров.
                </Typography>
                <Typography>
                  3.2.3. Направление уведомлений, запросов и информации, касающихся использования Сайта, исполнения
                  соглашений и договоров, а также обработка запросов и заявок от Пользователя.
                </Typography>
                <Typography>
                  3.2.4. Улучшение качества работы Сайта, удобства его использования для Пользователя, разработка новых
                  услуг и сервисов.
                </Typography>
                <Typography>3.2.5. Таргетирование рекламных материалов.</Typography>
                <Typography>
                  3.2.6. Проведение статистических и иных исследований на основе обезличенных данных.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">
                  4. Условия обработки персональной информации Пользователей и её передачи третьим лицам{' '}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  4.1. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
                  добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
                  лиц.
                </Typography>
                <Typography>
                  4.2. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:
                </Typography>
                <Typography>4.2.1. Пользователь выразил согласие на такие действия;</Typography>
                <Typography>
                  4.2.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения
                  определенного соглашения или договора с Пользователем.
                </Typography>
                <Typography>
                  4.2.3. Передача необходима для функционирования и работоспособности самого Сайта.
                </Typography>
                <Typography>
                  4.2.4. Передача предусмотрена российским или иным применимым законодательством в рамках установленной
                  законодательством процедуры.
                </Typography>
                <Typography>
                  4.2.5. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части),
                  при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики
                  применительно к полученной им персональной информации.
                </Typography>
                <Typography>
                  4.2.6. В целях обеспечения возможности защиты прав и законных интересов Сайта или третьих лиц в
                  случаях, когда Пользователь нарушает Пользовательское соглашение Сайта, настоящую Политику, либо
                  документы, содержащие условия использования конкретных сервисов.
                </Typography>
                <Typography>
                  4.2.7. В результате обработки персональной информации Пользователя путем ее обезличивания получены
                  обезличенные статистические данные, которые передаются третьему лицу для проведения исследований,
                  выполнения работ или оказания услуг по поручению Сайта.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">
                  5. Изменение и удаление персональной информации. Обязательное хранение данных
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  5.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную
                  информацию или её часть, обратившись к Сайту по контактам в разделе 9.«Контакты».
                </Typography>
                <Typography>
                  5.2. Права, предусмотренные п. 5.1. настоящей Политики могут быть ограничены в соответствии с
                  требованиями законодательства. Например, такие ограничения могут предусматривать обязанность Сайта
                  сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством,
                  и передать такую информацию в соответствии с законодательно установленной процедурой государственному
                  органу.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">
                  6. Обработка персональной информации при помощи файлов Cookie и счетчиков
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  6.1. Файлы cookie, передаваемые Сайтом оборудованию Пользователя и оборудованием Пользователя Сайту,
                  могут использоваться Сайтом для предоставления Пользователю персонализированных сервисов, для
                  таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях,
                  а также для улучшения Сайта.
                </Typography>
                <Typography>
                  6.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения
                  сайтов в сети интернет могут обладать функцией запрещения операций с файлами cookie (для любых сайтов
                  или для определенных сайтов), а также удаления ранее полученных файлов cookie.
                </Typography>
                <Typography>
                  6.3. Яндекс вправе установить, что предоставление определенного сервиса или услуги возможно только при
                  условии, что прием и получение файлов cookie разрешены Пользователем.
                </Typography>
                <Typography>
                  6.4. Структура файла cookie, его содержание и технические параметры определяются Сайтом и могут
                  изменяться без предварительного уведомления Пользователя.
                </Typography>
                <Typography>
                  6.5. Счетчики, размещенные Сайтом, могут использоваться для анализа файлов cookie Пользователя, для
                  сбора и обработки статистической информации об использовании Сайта, а также для обеспечения
                  работоспособности Сайта в целом или их отдельных функций в частности. Технические параметры работы
                  счетчиков определяются Сайтом и могут изменяться без предварительного уведомления Пользователя.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">7. Защита персонально информации Пользователя</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  7.1. Сайт предпринимает необходимые и достаточные организационные и технические меры для защиты
                  персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                  блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">8. Изменение Политики конфиденциальности</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  8.1. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении
                  изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики
                  вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.
                  Действующая редакция постоянно доступна на странице по адресу picpack.io.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">9. Контакты и вопросы по персональным данным</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  9.1. Все предложения, вопросы, запросы и иные обращения по поводу настоящей Политики и использования
                  своих персональных данных Пользователь вправе направлять Сайту: -по адресу электронной почты:
                  admin@picpack.io -по почтовому адресу: 108849, г. Москва, ул. Бориса Пастернака, д. 29, корп. 1, офис
                  301
                </Typography>
                <Typography>
                  <br />
                  Сайт: picpack.io <br />
                  Электронная почта для обращений - admin@picpack.io
                  <br />
                  ООО "МАЙНДСЭТ"
                  <br />
                  Адрес 108849, г. Москва, ул. Бориса Пастернака, д. 29, корп. 1, офис 301
                  <br />
                  ИНН 7751187490
                  <br />
                  КПП 775101001
                  <br />
                  ОГРН 1207700404933
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography variant={medium ? 'h2' : 'h4'} sx={{ mt: 5 }}>
              Публичная оферта
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
              Настоящий документ – это публичная оферта (предложение) интернет-ресурса “picpack.io” (далее - Сайт), о
              продаже услуг в сфере обработки и анализа изображений.
            </Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">1. Общие положения</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1.1. Настоящая публичная оферта (далее - Оферта) является официальным предложением ООО "МАЙНДСЭТ" в
                  адрес любого физического лица заключить с ООО "МАЙНДСЭТ" договор розничной купли-продажи услуг на
                  Сайте дистанционным образом на условиях, определенных в настоящем Договоре и содержит все существенные
                  условия Оферты.
                </Typography>
                <Typography>
                  1.2. Заказ Покупателем услуг, размещенных на Сайте, означает, что Покупатель согласен со всеми
                  условиями настоящей Оферты, Политики конфиденциальности и Пользовательского соглашения.
                </Typography>
                <Typography>1.3. Сайт имеет право вносить изменения в Оферту без уведомления Покупателя.</Typography>
                <Typography>1.4. Срок действия Оферты не ограничен, если иное не указано на Сайте.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">2. Предмет Оферты</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  2.1. Сайт обязуется передать Покупателю результат оказанной услуги, предназначенный для личного,
                  семейного, домашнего или иного использования, на основании размещенных Заказов, а Покупатель обязуется
                  принять и оплатить Услугу на условиях настоящей Оферты.
                </Typography>
                <Typography>
                  2.2. Наименование, цена, количество услуг, а также прочие необходимые условия Оферты определяются на
                  основании сведений, предоставленных Покупателем при оформлении заказа.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">3. Стоимость услуг</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  3.1. Цены на услуги определяются Продавцом в одностороннем бесспорном порядке и указываются на
                  страницах интернет-ресурса, расположенного по интернет-адресу: picpack.io
                </Typography>
                <Typography>
                  3.2. Цена услуги указывается в рублях Российской Федерации и включает в себя налог на добавленную
                  стоимость.
                </Typography>
                <Typography>
                  3.3. Окончательная цена услуги определяется последовательным действием на цену услуги скидок по
                  следующему порядку:
                  <br />
                  • Акционная скидка
                  <br />
                  • Скидка по промокоду
                  <br />• Скидка постоянного Покупателя
                </Typography>
                <Typography>
                  3.4. Расчеты между Сайтом и Покупателем за услуги производятся способами, указанными на Сайте в
                  разделе – FAQ (Часто Задаваемые Вопросы).
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">4. Момент заключения Оферты</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  4.1. Акцептом настоящей Оферты (договора) является оформление Покупателем заказа на услугу в
                  соответствии с условиями настоящей Оферты. Оформление Покупателем заказа на услугу производится путем
                  совершения действий указанных в разделе «FAQ (Часто Задаваемые Вопросы)» пункт «Как сделать заказ».
                </Typography>
                <Typography>
                  4.2. Акцептируя настоящую Оферту, Покупатель выражает согласие в том, что:
                  <br /> - регистрационные данные (в том числе персональные данные) указаны им добровольно;
                  <br /> - регистрационные данные (в том числе персональные данные) передаются в электронной форме по
                  каналам связи сети «Интернет»;
                  <br /> - регистрационные данные (в том числе персональные данные) переданы Сайту для реализации целей,
                  указанных в настоящей Оферте, Политике конфиденциальности, Пользовательском соглашении и могут быть
                  переданы третьим лицам, для реализации целей, указанных в настоящей Оферте;
                  <br /> - регистрационные данные (в том числе персональные данные) могут быть использованы Сайтом в
                  целях продвижения услуг, путем осуществления прямых контактов с Покупателем с помощью каналов связи;
                  <br /> - в целях дополнительной защиты от мошеннических действий указанные Покупателем регистрационные
                  данные (в том числе персональные данные) могут быть переданы банку, осуществляющему транзакции по
                  оплате оформленных заказов;
                  <br /> - данное Покупателем согласие на обработку его регистрационных данных (в том числе персональных
                  данных) является бессрочным и может быть отозвано Покупателем или его законным представителем, подачей
                  письменного заявления, переданного Сайту.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">5. Возврат денежных средств</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  5.1.Возврат денежных средств осуществляется в соответствии с Законом РФ «О защите прав потребителей»,
                  за вычетом понесенных исполнителем расходов.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">6. Получение результатов услуг</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  6.1.Оказание услуг Покупателю осуществляется в сроки, согласованные Сторонами при подтверждении заказа
                  сотрудником Сайта.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">7. Срок действия Оферты</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  7.1. Настоящая Оферта вступает в силу с момента ее акцепта Покупателем, и действует до момента отзыва
                  акцепта публичной Оферты.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">8. Дополнительные условия</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  8.1. Сайт вправе переуступать либо каким-либо иным способом передавать свои права и обязанности,
                  вытекающие из его отношений с Покупателем, третьим лицам.
                </Typography>
                <Typography>
                  8.2. Сайт и предоставляемые сервисы могут временно частично или полностью недоступны по причине
                  проведения профилактических или иных работ или по любым другим причинам технического характера.
                  Техническая служба Сайта имеет право периодически проводить необходимые профилактические или иные
                  работы с предварительным уведомлением Покупателей или без такового.
                </Typography>
                <Typography>
                  8.3. К отношениям между Покупателем и Сайтом применяются положения Российского законодательства.
                </Typography>
                <Typography>
                  8.4. В случае возникновения вопросов и претензий со стороны Покупателя он должен обратиться к Сайту по
                  электронной почте указанной на Сайте или иным доступным способом. Все возникающее споры стороны будут
                  стараться решить путем переговоров, при недостижении соглашения спор будет передан на рассмотрение в
                  судебный орган в соответствии с действующим законодательством РФ.
                </Typography>
                <Typography>
                  8.5. Признание судом недействительности какого-либо положения настоящего Соглашения не влечет за собой
                  недействительность остальных положений.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">9. Реквизиты Сайта</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Сайт: picpack.io <br />
                  Электронная почта для обращений - admin@picpack.io
                  <br />
                  ООО "МАЙНДСЭТ"
                  <br />
                  Адрес 108849, г. Москва, ул. Бориса Пастернака, д. 29, корп. 1, офис 301
                  <br />
                  ИНН 7751187490
                  <br />
                  КПП 775101001
                  <br />
                  ОГРН 1207700404933
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography variant={medium ? 'h2' : 'h4'} sx={{ mt: 5 }}>
              Пользовательское соглашение
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
              Настоящий документ «Пользовательское соглашение» (далее - Соглашение) представляет собой предложение ООО
              "МАЙНДСЭТ", размещенное на сайте picpack.io (далее - «Сайт»), заключить договор на изложенных ниже
              условиях Соглашения.
            </Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">1. Общие положения</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  1.1. Вы настоящим подтверждаете, что с момента регистрации на Сайте и в течение времени пользования
                  Сайтом, а также персонализированными сервисами Сайта, вы являетесь Пользователем Сайта вплоть до
                  вашего личного обращения в администрацию Сайта с требованием об отказе от любых взаимоотношений с
                  Сайтом.
                </Typography>
                <Typography>
                  1.2. Использование вами Сайта любым способом и в любой форме в пределах его объявленных функциональных
                  возможностей, включая: <br />
                  а) просмотр размещенных на Сайте материалов; <br />
                  б) регистрация и/или авторизация на Сайте; <br />
                  в) размещение или отображение на Сайте любых материалов, включая но не ограничиваясь такими как:
                  тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация;
                  создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438
                  Гражданского кодекса Российской Федерации.
                </Typography>
                <Typography>
                  1.3. Воспользовавшись любой из указанных выше возможностей по использованию Сайта вы подтверждаете,
                  что: <br />
                  а) ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сайта;
                  <br />
                  б) принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с
                  вашей стороны и обязуетесь их соблюдать или прекратить использование Сайта. Если вы не согласны с
                  условиями настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует
                  незамедлительно прекратить любое использование Сайта; <br />
                  в) Соглашение (в том числе любая из его частей) может быть изменено Сайтом без какого-либо
                  специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте
                  либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой
                  редакцией Соглашения.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">2. Общие условия пользования Сайтом</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  2.1. Сайт осуществляет продажу услуг посредством веб-ресурса picpack.io и сопутствующих сервисных
                  служб Сайта.
                </Typography>
                <Typography>
                  2.2. Сайт осуществляет доставку результатов оказания услуг посредством веб-ресурса picpack.io и
                  сопутствующих сервисных служб Сайта.
                </Typography>
                <Typography>
                  2.3. Сайт предоставляет доступ к персонализированным сервисам Сайта для получения наиболее полной
                  информации по интересующему вас товару, создания рейтингов и мнений, участия в конкурсных программах и
                  иных акциях, проводимых Сайтом.
                </Typography>
                <Typography>
                  2.4. Настоящим вы даете своё добровольное согласие на информирование вас обо всех действиях Сайта,
                  связанных с продажей товара и/или оказанием услуг, в том числе о статусе выполнения заказа, а также об
                  иных событиях любого характера, относящихся к сервисам Сайта.
                </Typography>
                <Typography>
                  2.5. Согласие на получение рассылки: Подписываясь на SMS- и email-рассылку picpack.io или третьих лиц,
                  уполномоченных на рассылку от имени picpack.io, в сети Интернет picpack.io, я соглашаюсь получать
                  информационную рассылку на телефон и электронный адрес, указанный мной при подписке через сервис
                  подписки информационной рассылки на сайте picpack.io. Я проинформирован о том, что в случае, если у
                  меня возникнет желание отказаться от информационной рассылки picpack.io, мне будет необходимо
                  самостоятельно перейти по ссылке «Отписаться от рассылки», указанной в тексте отправляемых сайтом
                  сообщений с электронного адреса Сайта info@picpack.io или же сообщить о несогласии получения
                  информационных рассылок по адресам, указанным в разделе «Контакты» данного Соглашения. Я также даю
                  разрешение Сайту или третьим лицам, уполномоченным на рассылку от имени Сайта, собирать, хранить и
                  обрабатывать все переданные мною в адрес Сайта персональные данные (в том числе фамилию, имя, отчество
                  и адрес электронной почты) с целью информирования о новостях и других событиях Сайта.
                </Typography>
                <Typography>
                  2.6. Вы соглашаетесь, что Сайт не несет никакой ответственности за задержки, сбои, неверную или
                  несвоевременную доставку, удаление или несохранность каких-либо уведомлений. При этом Сайт оставляет
                  за собой право повторного направления любого уведомления, в случае его неполучения Вами.
                </Typography>
                <Typography>
                  2.7. Все вопросы по информационной поддержке Вы можете задать по адресу admin@picpack.io
                </Typography>
                <Typography>
                  2.8. Вы признаёте, что сопровождающее товар описание на Сайте не претендует на исчерпывающую
                  информативность и может содержать неточности. Вы вправе направить все замечания по неточному описанию
                  товара Сайту по адресу admin@picpack.io
                </Typography>
                <Typography>
                  2.9. Вы признаёте, что Сайт прилагает достаточные усилия, чтобы внешний вид, упаковка и характеристики
                  товаров соответствовали описаниям, приведенным в каталоге Сайта. Одновременно Сайт доводит до вашего
                  сведения, что фактические внешний вид, упаковка и характеристики товара могут отличаться от этих
                  описаний в случае ассортиментного товара, а также в случае внесения изменений в товар непосредственно
                  производителем.
                </Typography>
                <Typography>
                  2.10. Вы проинформированы, что цена и наличие товара на Сайте изменяется на круглосуточно без
                  предварительного уведомления об этом и указываются в индивидуальном статусе и подробной карточке
                  товара, отображаемым в каталоге на Сайте.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">3. Обязательства Пользователя при использовании Сайта</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>3.1. Вы соглашаетесь не использовать сервисы Сайта с целью:</Typography>
                <Typography>
                  3.1.1. Загрузки контента, который является незаконным, нарушает любые права третьих лиц;
                  пропагандирует насилие, жестокость, ненависть и/или дискриминацию по расовому, национальному,
                  половому, религиозному, социальному признакам; содержит недостоверные сведения и/или оскорбления в
                  адрес конкретных лиц, организаций, органов власти.
                </Typography>
                <Typography>
                  3.1.2. Побуждения к совершению противоправных действий, а также содействия лицам, действия которых
                  направлены на нарушение ограничений и запретов, действующих на территории РФ.
                </Typography>
                <Typography>
                  3.1.3. Нарушения прав несовершеннолетних лиц и/или причинение им вреда в любой форме.
                </Typography>
                <Typography>3.1.4. Ущемления прав меньшинств.</Typography>
                <Typography>
                  3.1.5. Выдачи себя за другого человека или представителя организации и/или сообщества без достаточных
                  на то прав, в том числе за сотрудников Сайта.
                </Typography>
                <Typography>
                  3.1.6. Введения в заблуждение относительно свойств и характеристик каких-либо товаров\услуг;
                  некорректного сравнения товаров\услуг, а также формирования негативного отношения к лицам, (не)
                  пользующимся определенными товарами\услугами, или осуждения таких лиц.
                </Typography>
                <Typography>
                  3.1.7. Загрузки контента, который вы не имеете права делать доступным по законодательству РФ или
                  согласно каким-либо контрактным отношениям.
                </Typography>
                <Typography>
                  3.1.8. Загрузки контента, который затрагивает и/или содержит какой-либо патент, торговый знак,
                  коммерческую тайну, фирменное наименование, авторские и смежные с ними права, а равно прочие права на
                  результаты интеллектуальной деятельности, принадлежащие или правомерно используемые третьими лицами.
                </Typography>
                <Typography>
                  3.1.9. Загрузки не разрешенной специальным образом рекламной информации и/или спама.
                </Typography>
                <Typography>
                  3.1.10. Сбора и обработки персональных данных, информации о частной жизни любых лиц.
                </Typography>
                <Typography>3.1.11. Нарушения нормальной работы Сайта.</Typography>
                <Typography>3.1.12. Нарушения российских или международных норм права.</Typography>
                <Typography>
                  3.2. Вы соглашаетесь не использовать на Сайте бранных слов, непристойных и оскорбительных образов,
                  сравнений и выражений, в том числе в отношении пола, расы, национальности, профессии, социальной
                  категории, возраста, языка человека и гражданина, а также в отношении организаций, органов власти,
                  официальных государственных символов (флагов, гербов, гимнов), религиозных символов, объектов
                  культурного наследия (памятников истории и культуры).
                </Typography>
                <Typography>
                  3.3. Вы признаете и соглашаетесь, что Сайт имеет право (но не обязанность) по своему усмотрению
                  отказать в размещении и/или удалить любой контент, доступный через сервисы Сайта.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">4. Политика конфиденциальности</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  4.1. Условия Политики конфиденциальности и отношения между вами и Сайтом, связанные с обработкой
                  персональных данных, регулируются Федеральным Законом РФ №152-ФЗ от 27 июля 2006г. «О персональных
                  данных».
                </Typography>
                <Typography>
                  4.2. Политика конфиденциальности действует в отношении персональных данных, которые Сайт получил или
                  может получить от вас при регистрации и/или оформлении заказа на Сайте, и необходимые для выполнения
                  обязательств со стороны Сайта в отношении приобретаемого вами товара/услуги и/или вашего доступа к
                  сервисам Сайта.
                </Typography>
                <Typography>4.3. Политика конфиденциальности доступна и размещена на сайте picpack.io</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">5. Условия приобретения услуг</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  5.1. Вы можете приобрести услуги на Сайте, оплатив его следующими способами: кредитной картой,
                  электронными деньгами, с лицевого счёта мобильного телефона, банковским переводом по квитанции, иным
                  способом указанным на Сайте.
                </Typography>
                <Typography>
                  5.2. Настоящим вы соглашаетесь, что выбранный вами способ оплаты не подлежит изменению с момента
                  оформления заказа на Сайте.
                </Typography>
                <Typography>
                  5.3. Настоящим вы соглашаетесь, что подтверждение заказа, оплаченного электронными деньгами, кредитной
                  картой, с личного счета мобильного телефона, банковским переводом, происходит только после
                  подтверждения списания денежных средств в счёт оплаты заказа.
                </Typography>
                <Typography>
                  5.4. Вы подтверждаете, что оплата заказа электронными деньгами, кредитной картой, с личного счета
                  мобильного телефона должна быть произведена в течение 5 календарных дней с момента его оформления на
                  Сайте. Вы соглашаетесь, что в случае неоплаты заказа по истечении указанного срока, заказ может быть
                  аннулирован.
                </Typography>
                <Typography>
                  5.5. Сайт осуществляет доставку результатов оказания услуг посредством веб-ресурса picpack.io и
                  сопутствующих сервисных служб Сайта.
                </Typography>
                <Typography>
                  5.6. В случае вашего отказа от услуг, а также в случае отсутствия заказанной вами услуги,\
                  перечисленная Сайту предоплата за оказание услуг, за исключением понесенных расходов Сайта, будет
                  возвращена вам не позднее чем через 10 календарных дней с даты предъявления.
                </Typography>
                <Typography>
                  5.7. Все вопросы, связанные со сроками и условиями оказания услуг, вы можете направить в Сайту по
                  адресу – admin@picpack.io
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">6. Ответственность</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  6.1 Сервисы Сайта могут содержать ссылки на другие ресурсы. Вы признаете и соглашаетесь с тем, что
                  Сайт не несет никакой ответственности за доступность этих ресурсов и за их контент, а также за любые
                  последствия, связанные с использованием вами контента этих ресурсов.
                </Typography>
                <Typography>
                  6.2. Вы также соглашаетесь с тем, что Сайт не несёт никакой ответственности за ваши персональные
                  данные, которые вы предоставляете сторонним ресурсам и/или иным третьим лицам в случае перехода на них
                  с Сайта.
                </Typography>
                <Typography>
                  6.3. Вы подтверждаете, что Сайт не отвечает за возможную потерю и/или порчу данных, которая может
                  произойти из-за нарушения вами положений настоящего Соглашения, а также неправильного доступа и/или
                  использования персонализированных сервисов Сайта.
                </Typography>
                <Typography>
                  6.4. Ответственность за действия несовершеннолетних, включая приобретение ими товаров из каталога на
                  Сайте, лежит на законных представителях несовершеннолетних.
                </Typography>
                <Typography>
                  6.5. Вы соглашаетесь, что в случае неисполнения и/или ненадлежащего исполнения Сайтом обязательств по
                  продаже и/или доставке вам товара в связи с предоставлением вами недостоверных и/или недействительных
                  данных о себе, а равно невыполнение вами условий настоящего Соглашения, Сайт ответственности не несет.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                <Typography variant="h6">7. Реквизиты Сайта</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Сайт: picpack.io <br />
                  Электронная почта для обращений - admin@picpack.io
                  <br />
                  ООО "МАЙНДСЭТ"
                  <br />
                  Адрес 108849, г. Москва, ул. Бориса Пастернака, д. 29, корп. 1, офис 301
                  <br />
                  ИНН 7751187490
                  <br />
                  КПП 775101001
                  <br />
                  ОГРН 1207700404933
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Fade>
      </Container>
    </RootStyle>
  );
}
