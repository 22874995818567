/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { styled } from '@material-ui/core/styles';
import {
  Card,
  Button,
  Typography,
  Box,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from '@material-ui/core';
// redux
import { useDispatch } from '../../../redux/store';
import { setPrice, setCount } from '../../../redux/slices/pay';
import useLocales from '../../../hooks/useLocales';
// ---------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  height: 750,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: theme.spacing(3),
  paddingTop: 40
}));

// ----------------------------------------------------------------

export default function PricingCard({ plan, choosePlanHandler }) {
  const [result, setResult] = useState('');
  const dispatch = useDispatch();
  const { currentLang } = useLocales();

  const handleChange = (event) => {
    setResult(event.target.value);
    const [count] = event.target.value.match(/\d{3,4}/);
    dispatch(setCount(Number(count)));
    switch (count) {
      case '100':
        dispatch(setPrice(1000));
        break;
      case '500':
        dispatch(setPrice(3000));
        break;
      case '1000':
        dispatch(setPrice(4000));
        break;
      default:
        break;
    }
  };

  return plan?.customPlan ? (
    <>
      <RootStyle
        sx={
          !plan?.isSpecial
            ? { backgroundColor: 'background.paper' }
            : { backgroundColor: 'background.alternativePaper' }
        }
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid
            sx={{
              height: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="overline"
              sx={plan?.isSpecial ? { color: 'text.secondary' } : { color: 'text.alternativeSecondary' }}
            >
              {plan?.subscription}
            </Typography>
          </Grid>
          <Box sx={{ width: 80, height: 80, mt: 3 }}>{plan?.icon}</Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={result}
              onChange={handleChange}
            >
              {plan?.lists.map((item) => (
                <FormControlLabel
                  key={plan?.id + item.text}
                  spacing={2}
                  sx={{ my: 3, width: 1 }}
                  value={item.text}
                  control={<Radio />}
                  label={item.text}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {result ? (
            <Typography
              variant="overline"
              sx={{
                textAlign: 'center',
                backgroundColor: '#00ab55',
                borderRadius: 1,
                color: 'white',
                marginTop: '50px',
                padding: '10px'
              }}
            >
              {currentLang.value === 'ru' ? `Вы выбрали ${result}` : `You chose ${result}`}
            </Typography>
          ) : null}
        </Grid>
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled={!result}
          data-plan={plan.id}
          sx={{ textAlign: 'center' }}
          onClick={choosePlanHandler()}
        >
          {plan?.labelAction}
        </Button>
      </RootStyle>
    </>
  ) : (
    <>
      <RootStyle
        sx={
          !plan?.isSpecialchoosePlanHandler
            ? { backgroundColor: 'background.paper' }
            : { backgroundColor: 'background.alternativePaper' }
        }
      >
        <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Grid
            sx={{
              height: '250px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="overline"
              sx={plan?.isSpecial ? { color: 'text.secondary' } : { color: 'text.alternativeSecondary' }}
            >
              {plan?.subscription}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
              <Typography
                variant="h4"
                sx={{
                  mx: 1,
                  color: !plan?.isSpecial ? 'text.primary' : 'text.alternativePrimary',
                  textAlign: 'center'
                }}
              >
                {plan?.discountPrice}
              </Typography>
              {!plan?.customPlan ? (
                <Typography
                  gutterBottom
                  component="span"
                  variant="subtitle2"
                  sx={{
                    alignSelf: 'flex-end',
                    color: 'text.secondary'
                  }}
                >
                  {/* {!checked ? translate('pricing.cards.month') : translate('pricing.cards.year')} */}
                </Typography>
              ) : (
                ''
              )}
            </Box>

            <Typography
              gutterBottom
              component="span"
              variant="subtitle1"
              sx={{
                textDecoration: 'line-through'
              }}
            >
              {plan?.price}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                color: 'primary.main',
                textTransform: 'capitalize'
              }}
            >
              {plan?.caption}
            </Typography>

            <Box sx={{ width: 80, height: 80, mt: 3 }}>{plan?.icon}</Box>
          </Grid>
          <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
            {plan?.lists.map((item) => (
              <Stack
                key={item.text}
                component="li"
                direction="row"
                alignItems="center"
                spacing={1.5}
                lineHeight={1.3}
                sx={
                  !item.isAvailable
                    ? { typography: 'body2', color: 'text.disabled' }
                    : { typography: 'body2', color: !plan?.isSpecial ? 'text.primary' : 'text.alternativePrimary' }
                }
              >
                <Box component={Icon} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
                <Typography variant="body2">{item.text}</Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Button
          fullWidth
          type="button"
          size="large"
          variant="contained"
          sx={{ textAlign: 'center' }}
          data-plan={plan.id}
          onClick={choosePlanHandler()}
        >
          {plan?.labelAction}
        </Button>
      </RootStyle>
    </>
  );
}
